import defaultTheme from "./default";

import { createMuiTheme } from "@material-ui/core";

import _ from 'lodash'

const overrides = {
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    },
  },
};

const tempTheme = createMuiTheme({
  palette: {
     primary: {
        main: "#0092b3" // This is an orange looking color
               },
     secondary: {
        main: "#fcb308" //Another orange-ish color
                }
           },
    tableDashboardXYLines: {
      main: "gray"
    }
});

const theme = _.assign(
  {},
  defaultTheme,
  tempTheme
);

export default {
  default: createMuiTheme({ ...theme, ...overrides }),
};