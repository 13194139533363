import { Button, CircularProgress, Container, Grid } from "@material-ui/core";
// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';
import React, { useState } from "react";
import { db, functions } from '../../../context/Firebase';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { switchPlace } from "../../../context/UserContext";
// Styles

export default function DeleteHouse(props) {

    var [successDeleteHouse] = useState(false);
    var [errorDeleteHouse, showErrorDeleteHouse] = useState(false);
    var [loadingDeleteHouse, setLoadingDeleteHouse] = useState(false);

    // Dialog AddNew Function
    const [dialogVisible, setDeleteHouseDialog] = useState(false)
    const handleClickOpen = () => {
        setDeleteHouseDialog(true);
    };
    const handleClose = () => {
        setDeleteHouseDialog(false);
    };

    const [houseOwners] = useState(props.houseOwners)
    const [currentHouseOwner, setCurrentHouseOwner] = useState(null)

    async function deleteHouseFunction() {
        const data = {
            placeID: props.communityIdProp.placeID,
            houseIDtoDelete: currentHouseOwner.houseid,
            validateAdmin: "B4A15G4kjf0se12kypoE",
            houseResidentsId: await getHouseResidentsIds(props.communityIdProp.placeType, props.communityIdProp.stateID, props.communityIdProp.placeID, currentHouseOwner.houseid)
        };
        var requestFunction = functions.httpsCallable('deleteHouse')
        requestFunction(data).then((values) => {
            if (values.data.code === 200) {
                setLoadingDeleteHouse(false)
                alert(values.data.message)
                var SuccessfullyLoginObject = {
                    UID: props.communityIdProp.UID,
                    Email: props.communityIdProp.Email,
                    stateID: props.communityIdProp.stateID,
                    placeID: props.communityIdProp.placeID,
                    placeType: props.communityIdProp.placeType,
                    placeList: props.communityIdProp.placeList,
                    displayedName: props.communityIdProp.displayedName,
                    timezone: props.communityIdProp.timezone
                }
                switchPlace(props.dispatch, SuccessfullyLoginObject)
                return;
            } else {
                setLoadingDeleteHouse(false)
                showErrorDeleteHouse(true)
                return;
            }
        }).catch((error) => {
            console.log(error)
            console.log("Error")
            setLoadingDeleteHouse(false)
            setLoadingDeleteHouse(false)
            showErrorDeleteHouse(true)
            return;
        })
    }
    return (
        <Container>
            <Grid container justify="center" alignItems="center">
                <Grid container justify="center" alignItems="center">
                    <Autocomplete
                        onChange={(event, value) => setCurrentHouseOwner(value)}
                        options={houseOwners}
                        getOptionLabel={(houseOwnerList) => houseOwnerList.address}
                        style={{ width: 300, maxHeight: 50, margin: 10 }}
                        renderInput={(params) => <TextField {...params} label="Search by Home Owner" variant="outlined" />}
                    />
                </Grid>
                <Grid container justify="center" alignItems="center">
                    <Grid container justify="center" alignItems="center">
                        {loadingDeleteHouse ? <CircularProgress style={{ margin: 15 }} /> : null}
                        {errorDeleteHouse ? <Alert severity="error">Some error occurd</Alert> : null}
                        {successDeleteHouse ? <Alert severity="success">Deleted house successfully!</Alert> : null}
                    </Grid>
                    <Grid container justify="center" alignItems="center">
                        <Button style={{ margin: 20 }} variant="contained" color="primary" onClick={() => {
                            if (currentHouseOwner !== undefined && currentHouseOwner !== null) {
                                handleClickOpen()
                            } else {
                                return;
                            }
                        }}>
                            Delete House
                        </Button>
                    </Grid>
                </Grid>
                <Dialog
                    open={dialogVisible}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete house permanent"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            All info and data about this house will be deleted forever,
                            Are you sure you want to delete this House?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            No
                                        </Button>
                        <Button onClick={() => {
                            showErrorDeleteHouse(false)
                            handleClose()
                            setLoadingDeleteHouse(true)
                            deleteHouseFunction()
                        }}
                            color="primary"
                            autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Container>
    )
}

function getHouseResidentsIds(placeType, stateID, placeID, houseID) {
    return db.ref('/' + placeType + '/' + stateID + '/' + placeID + '/' + houseID).once("value")
    .then((e) => {
        const houseSnapshot = e.val()
        const array = [];
        for (const residentID in houseSnapshot) {
            if (residentID.substring(0, 8) !== "database") {
                if (houseSnapshot[residentID]['expotoken'] !== null && houseSnapshot[residentID]['expotoken'] !== undefined) {
                    array.push(residentID)
                }
            }
        }
        return array;
    })
    .catch((error) => {
        console.log(error)
        return;
    })

}