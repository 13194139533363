import { Button, CircularProgress, Container, Grid } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// Styles
import React, { useState } from "react";
// components
import { functions } from '../../../context/Firebase';



export default function BlastNotification(props) {
    var [successSendBlast, showSuccessSendBlast] = useState(false);
    var [loadingSendBlast, setLoadingSendBlast] = useState(false);

    var [messageTitle, setMessageTitle] = useState("")
    var [messageBody, setMessageBody] = useState("")

    async function sendBlast() {
        if (messageBody !== "" && messageTitle !== "") {
            functions.httpsCallable('sendPushNotification')({ placeID: props.communityIdProp.placeID, messageBody: messageBody, messageTitle: messageTitle, validateCode: "ionm92378ASDAS" })
                .then((response) => {
                    if (response.data.code === 200) {
                        showSuccessSendBlast(true)
                    } else {
                        alert(response.data.message)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    setLoadingSendBlast(false)
                })
        } else {
            alert("Please provide message")
            setLoadingSendBlast(false)
        }
    }
    return (
        <Container maxWidth="sm">
            <TextField
                required rows={1}
                rowsMax={10} label="Subject" placeholder={"Type Here..."}
                defaultValue={messageTitle}
                variant="outlined"
                style={{ width: 600 }}
                onChange={(e) => setMessageTitle(e.target.value)}
                margin={'dense'} multiline fullWidth={true} />
            <TextField
                required rows={2}
                rowsMax={10} label="Message content" placeholder={"Type Here..."}
                defaultValue={messageBody}
                variant="outlined"
                style={{ width: 600 }}
                onChange={(e) => setMessageBody(e.target.value)}
                margin={'dense'} multiline fullWidth={true} />
            <Grid container justify="center" alignItems="center">
                <Grid container justify="center" alignItems="center">
                    {loadingSendBlast ? <CircularProgress style={{ margin: 15 }} /> : null}
                    {successSendBlast ? <Alert severity="success">Blast sent!</Alert> : null}
                </Grid>
                <Grid container justify="center" alignItems="center">
                    <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={() => {
                        setLoadingSendBlast(true)
                        sendBlast()
                    }}>
                        Send
                    </Button>
                </Grid>


            </Grid>
        </Container>
    )
}