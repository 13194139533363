import { Button, CircularProgress, Container, Grid } from "@material-ui/core";
// Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';
import React, { useState } from "react";
import { functions } from '../../../context/Firebase';
import { switchPlace } from "../../../context/UserContext";

// Styles

export default function AddNewHouse(props) {

    var [successAddNewHouse] = useState(false);
    var [errorAddNewHouse, showErrorAddNewHouse] = useState(false);
    var [loadingAddNewHouse, setLoadingAddNewHouse] = useState(false);
    var [errorMessageContentNewHouse, setErrorMessageContentNewHouse] = useState("Some Error Occurrd");

    // Delete House Function
    // const [currentHouseOwner, setHouseOwner] = useState(null);
    // Update House Function
    const [newhouseOwnerName, setNewHouseOwnerName] = useState("")
    const [newhouseOwnerPhone, setNewHouseOwnerPhone] = useState("")
    const [newhouseOwnerEmail, setNewHouseOwnerEmail] = useState("")
    const [newhouseAddress, setNewHouseAddress] = useState("")

    // Dialog AddNew Function
    const [dialogVisibleAddNew, setAddNewHouseDialog] = useState(false)
    const handleClickOpenAddNew = () => {
        setAddNewHouseDialog(true);
    };
    const handleCloseAddNew = () => {
        setAddNewHouseDialog(false);
    };

    function addNewHouseFunction() {
        const data = {
            ownerFullname: newhouseOwnerName,
            ownerPhone: newhouseOwnerPhone,
            ownerEmail: newhouseOwnerEmail,
            ownerAddress: newhouseAddress,
            placeID: props.communityIdProp.placeID,
            stateID: props.communityIdProp.stateID,
            placeType: props.communityIdProp.placeType,
            validateAdmin: "B4A15G4kjf0se12kypoE",
        };
        var requestFunction = functions.httpsCallable('AddNewHome')
        requestFunction(data).then((values) => {
            if (values.data.code === 200) {
                setLoadingAddNewHouse(false)
                alert(values.data.message)
                var SuccessfullyLoginObject = {
                    UID: props.communityIdProp.UID,
                    Email: props.communityIdProp.Email,
                    stateID: props.communityIdProp.stateID,
                    placeID: props.communityIdProp.placeID,
                    placeType: props.communityIdProp.placeType,
                    placeList: props.communityIdProp.placeList,
                    displayedName: props.communityIdProp.displayedName,
                    timezone: props.communityIdProp.timezone
                }
                switchPlace(props.dispatch, SuccessfullyLoginObject)
                return;
            } else {
                setErrorMessageContentNewHouse(values.data.message)
                setLoadingAddNewHouse(false)
                showErrorAddNewHouse(true)
                return;
            }
        }).catch((error) => {
            console.log(error)
            console.log("Error")
            setErrorMessageContentNewHouse(error.message)
            setLoadingAddNewHouse(false)
            showErrorAddNewHouse(true)
            return;
        })
    }
    return (
        <Container>
            <Grid container justify="center" alignItems="center">
                <label style={{ margin: 10 }}>New House Info</label>
                <Grid container justify="center" alignItems="center">
                    <TextField
                        style={{ margin: 10 }}
                        required
                        label="Owner Full Name"
                        defaultValue={newhouseOwnerName}
                        onChange={(e) => setNewHouseOwnerName(e.target.value)}
                        variant="filled"
                    />
                    <TextField
                        style={{ margin: 10 }}
                        required
                        label="Owner Phone"
                        defaultValue={newhouseOwnerPhone}
                        onChange={(e) => setNewHouseOwnerPhone(e.target.value)}
                        variant="filled"
                    />
                </Grid>
                <Grid container justify="center" alignItems="center">
                    <TextField
                        style={{ margin: 10 }}
                        required
                        label="Owner Email"
                        defaultValue={newhouseOwnerEmail}
                        onChange={(e) => setNewHouseOwnerEmail(e.target.value)}
                        variant="filled"
                    />
                    <TextField
                        style={{ margin: 10 }}
                        required
                        label="Full House Address"
                        defaultValue={newhouseAddress}
                        onChange={(e) => setNewHouseAddress(e.target.value)}
                        variant="filled"
                    />
                </Grid>
                <Grid container justify="center" alignItems="center">
                    {loadingAddNewHouse ? <CircularProgress style={{ margin: 15 }} /> : null}
                    {errorAddNewHouse ? <Alert severity="error">{errorMessageContentNewHouse}</Alert> : null}
                    {successAddNewHouse ? <Alert severity="success">Added a new house successfully!</Alert> : null}
                </Grid>
                <Grid container justify="center" alignItems="center">
                    <Button style={{ margin: 20 }} variant="contained" color="primary" onClick={() => {
                        if (newhouseOwnerEmail !== "" && newhouseOwnerPhone !== "" && newhouseOwnerName !== "") {
                            handleClickOpenAddNew()
                        } else {
                            return;
                        }
                    }}>
                        Add a new house
                </Button>
                </Grid>
            </Grid>
            <Dialog
                open={dialogVisibleAddNew}
                onClose={handleCloseAddNew}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Add a new house"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please validate all info is correct before continue
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddNew} color="primary">
                        Abort
                </Button>
                    <Button onClick={() => {
                        handleCloseAddNew()
                        setLoadingAddNewHouse(true)
                        showErrorAddNewHouse(false)
                        addNewHouseFunction()
                    }}
                        color="primary"
                        autoFocus>
                        Confirm
                </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
