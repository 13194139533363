import moment from 'moment-timezone';
import { db, firestore } from '../../context/Firebase.js';

export async function getListsForCommunity(userObject) {
  const ResidentList = [];
  let result;
  if (userObject == null) return result;
  else {
    const answer = firestore.collection(userObject.placeType).doc(userObject.placeID).collection("houses").doc("residentList").get()
      .then(async (normalResidentList) => {
        console.log("Runned")
        const data = normalResidentList.data()
        Object.keys(normalResidentList.data()).forEach((residentID) => {
          ResidentList.push([residentID, data[residentID]['fullname'], data[residentID]['email'], makePhoneOk(data[residentID]['phone']), data[residentID]['phone'], data[residentID]['address']])
        })
        var TodayDocName = moment.tz(userObject.timezone).format("MMDDYYYY");
        const guestForToday = await firestore.collection(userObject.placeType).doc(userObject.placeID).collection("activePasses").doc(TodayDocName).get()
          .then((values) => {
            var helperarray = [];
            const data = values.data().guestlist;
            var residentListValues = normalResidentList.data()
            for (var residentId of Object.keys(data)) {
              for (var guestId of Object.keys(data[residentId])) {
                if (residentListValues[residentId] !== undefined) {
                  let plateNumber = "";
                  if (data[residentId][guestId]['plate'] !== undefined && data[residentId][guestId]['plate'] !== null) plateNumber = data[residentId][guestId]['plate'];
                  const object = {
                    id: guestId,
                    guestname: data[residentId][guestId]['guestname'],
                    plate: plateNumber,
                    residentName: residentListValues[residentId]['fullname'],
                    residentPhone: residentListValues[residentId]['phone'],
                    residentAddress: residentListValues[residentId]['address'],
                    enddate: moment.tz(data[residentId][guestId]['lefttime'], userObject.timzone).format("MM/DD/YY")
                  }
                  helperarray.push(object)
                }
              }
            }
            return helperarray;
          }).catch((error) => {
            console.log(error)
            if (error.code === "permission-denied") {
              localStorage.removeItem("currUser")
              localStorage.removeItem("communityData")
            }
          })
        return {
          residentListMap: normalResidentList.data(),
          residentListArray: ResidentList,
          houseOwnersMap: getHouseOwners(normalResidentList.data()),
          guestListArray: guestForToday,
          userObject: userObject
        }
      }).catch((error) => {
        console.log(error)
        if (error.code === "permission-denied") {
          localStorage.removeItem("currUser")
          localStorage.removeItem("communityData")
        }
      })
    result = await answer;
    return result;
  }
}


export async function refreshResidents() {
  var ResidentList = [];
  const communityData = JSON.parse(localStorage.getItem("LoginInfo")).communityInfo
  var answer = firestore.collection("communities").doc(communityData.CommunityID).collection("houses").doc("residentList").get()
    .then(async (normalResidentList) => {
      const data = normalResidentList.data()
      Object.keys(normalResidentList.data()).forEach((residentID) => {
        ResidentList.push([residentID, data[residentID]['email'], data[residentID]['fullname'], data[residentID]['phone'], data[residentID]['address']])
      })
      return {
        residentListMap: normalResidentList.data(),
        residentListArray: ResidentList,
      }
    }).catch((error) => {
      console.log(error)
    })
  var result = await answer;
  return result;
}

function getHouseOwners(map) {
  const array = []
  for (const residentID of Object.keys(map)) {
    if (residentID === map[residentID]['houseid']) {
      array.push(map[residentID])
    }
  }
  return array;
}

function makePhoneOk(phone) {
  if (phone === undefined || phone === null || String(phone).length < 10) return phone;
  return "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + "-" + phone.substring(6, 10)
}

export async function getResidentInfo(userObject) {
  let result;
  if (userObject === null) return result;
  else {
    // const answer = firestore.collection(userObject.placeType).doc(userObject.placeID).collection("houses").doc(userObject.houseID).get()
    //   .then((d) => {
    //     const houseDocument = d.data()
    const answer = db.ref('/' + userObject.placeType + '/' + userObject.stateID + '/' + userObject.placeID + '/' + userObject.houseID).once("value")
      .then((e) => {
        if (e.val()) {
          const houseSnapshot = e.val()
          const houseResidentsWhoSignedUp = {};
          for (const residentID in houseSnapshot) {
            if (residentID.substring(0, 8) !== "database") {
              if (houseSnapshot[residentID]['expotoken'] !== null && houseSnapshot[residentID]['expotoken'] !== undefined) {
                houseResidentsWhoSignedUp[residentID] = {
                  residentName: houseSnapshot[residentID]['fullname'],
                  residentPhone: houseSnapshot[residentID]['phone'],
                  residentID: residentID
                }
              }
            }
          }
          return {
            houseResidentWhoSignedUp: houseResidentsWhoSignedUp,
            houseAddress: houseSnapshot['databaseAddress'],
            houseID: userObject.houseID,
            openHouseValue: houseSnapshot['databaseOpenHouse']
          }
        } else {
          return {
            houseResidentWhoSignedUp: [],
            houseID: userObject.houseID,
            houseAddress: "",
            openHouseValue: false
          }
        }
      }).catch((error) => {
        console.log(error)
        if (error.code === "permission-denied") {
          localStorage.removeItem("currUser")
          localStorage.removeItem("communityData")
        }
      })
    const result = await answer;
    return result;
  }
}