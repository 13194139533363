import React from "react";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import { Grid, Card, Typography } from "@material-ui/core";




export default function Updates(props) {
    return (
        <>
            <PageTitle title={"Residents Announcements"} />
            <Grid container justify="center" spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <Typography>Test</Typography>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}