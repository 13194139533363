import { Button, Grid, TextField } from "@material-ui/core";
// Dialog 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from "react";
// Add Guest
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SplitButton from "./SplitButton";
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { handleRequestButton } from './functions'
import useStyles from './styles'


export default function AddGuestDialog(props) {
    const [firstGroupValue, setFirstGroupValue] = useState("temp")
    const [secondGroupValue, setSecondGroupValue] = useState("guest")
    const [guestFirstNameState, setGuestFirstNameState] = useState("")
    const [guestSecondNameState, setGuestSecondNameState] = useState("")
    const [plateAddGuestState, setLicensePlateState] = useState("")
    var classes = useStyles();

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [startDateValue, setStartDateValue] = useState(null)
    const [endDateValue, setEndDateValue] = useState(null)
    const [startDispalyed, setStartDisplayed] = useState("Select Start Date")
    const [endDispalyed, setEndDisplayed] = useState("Select End Date")

    const startDateChange = (date) => {
        setStartDate(date);
        setStartDisplayed(new Date(date).toLocaleDateString())
        setStartDateValue(moment.tz(date, props.placeValues.timezone).set({ hour: 0, minute: 0 }).unix())
    }
    const endDateChange = (date) => {
        setEndDate(date);
        setEndDisplayed(new Date(date).toLocaleDateString())
        setEndDateValue(moment.tz(date, props.placeValues.timezone).set({ hour: 23, minute: 59 }).unix())
    }

    function getUserInfo() {
        return {
            placeType: String(props.placeValues.placeType),
            stateID: String(props.placeValues.stateID),
            placeID: String(props.placeValues.placeID),
            timezone: String(props.placeValues.timezone),
            houseID: String(props.houseInfo.houseid),
            residentID: String(props.houseInfo.houseid),
            residentName: String(props.houseInfo.fullname),
            residentAddress: String(props.houseInfo.address),
            residentPhone: String(props.houseInfo.phone)
        }
    }
    function createGuestObject(guestObject) {
        let handleLeftTime = 0;
        if (guestObject.timeLimit !== "perm") handleLeftTime = guestObject.timeLimit;
        return {
            guestID: guestObject.guestID,
            guestName: guestObject.guestname,
            job: guestObject.job,
            lefttime: handleLeftTime,
            plate: plateAddGuestState,
            residentName: String(props.houseInfo.fullname),
            residentPhone: String(props.houseInfo.phone),
            residentid: String(props.houseInfo.houseid),
            type: guestObject.guestType,
        }
    }
    function handleClickAddGuest() {
        if (guestFirstNameState === "" || guestFirstNameState === undefined || guestFirstNameState === null) {
            alert("Please fill guest name")
            return;
        } else {
            return handleRequestButton(getUserInfo(), secondGroupValue, firstGroupValue, guestFirstNameState, guestSecondNameState, startDateValue, endDateValue, plateAddGuestState)
                .then((e) => {
                    if (e.addToList) {
                        const returnArray = props.currentGuestList;
                        returnArray.push(createGuestObject(e))
                        props.refreshGuestList(returnArray)
                    }
                    restartOldValues()
                    props.toastSuccess(true)
                    props.closeDialogFunction(false)
                    return;
                }).catch((error) => {
                    console.log(error)
                    alert(error.message)
                    return;
                })
        }
    }
    function restartOldValues() {
        setLicensePlateState("")
        setStartDate(new Date());
        setEndDate(new Date());
        setStartDisplayed("Select Start Date")
        setEndDisplayed("Select End Date")
        setStartDateValue(null)
        setEndDateValue(null)
        setGuestFirstNameState("")
        setGuestSecondNameState("")
        return;
    }
    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={props.dialogState} onClose={() => props.closeDialogFunction(false)} aria-labelledby="form-dialog-title">
            <DialogTitle style={{ textAlign: 'center' }} id="form-dialog-title">Add a guest</DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
                <Grid style={{ height: 490 }} container spacing={1}>
                    <Grid item container xs={"auto"} justify="center" alignItems="center" >
                        <SplitButton setParentValue={setFirstGroupValue} realValue={firstGroupValue} firstValue={"temp"} firstOption={"Temporary"} secondValue={"perm"} secondOption={"Permanent"} />
                        {/* </Grid>
                    <Grid item container xs={"auto"} justify="center" alignItems="center"> */}
                        <SplitButton setParentValue={setSecondGroupValue} realValue={secondGroupValue} firstValue={"guest"} firstOption={"Guest"} secondValue={"vendor"} secondOption={"Vendor"} />
                    </Grid>
                    <Grid spacing={1} container justify="center" alignItems="center">
                        <Grid container item xs={12} justify="center" alignItems="center">
                            <TextField
                                autoFocus
                                required
                                id="nameFirstId"
                                label={(secondGroupValue === "guest") ? "First Name" : "Vendor Full Name/Company"}
                                style={{ textAlign: 'center', width: 260 }}
                                onChange={(event) => setGuestFirstNameState(event.target.value)}
                            />
                        </Grid>
                        <Grid container item xs={12} justify="center" alignItems="center">
                            <TextField
                                required
                                id="nameSecondId"
                                label={(secondGroupValue === "guest") ? "Last Name" : "Job"}
                                style={{ textAlign: 'center', width: 260 }}
                                onChange={(event) => setGuestSecondNameState(event.target.value)}
                            />
                        </Grid>
                        <Grid container item xs={12} justify="center" alignItems="center">
                            <TextField
                                id="plateid"
                                style={{ textAlign: 'center', width: 260 }}
                                label="License Plate"
                                onChange={(event) => setLicensePlateState(event.target.value)}
                            />
                        </Grid>
                    </Grid>
                    {(firstGroupValue === "temp") ? <Grid spacing={1} container justify="center" alignItems="center">
                        <Grid item container xs={"auto"} justify="center" alignItems="center">
                            <DatePicker
                                selected={startDate}
                                onChange={date => startDateChange(date)}
                                startDate={startDate}
                                endDate={endDate}
                                minDate={new Date()}
                                withPortal
                                customInput={<button className={classes.showDateButton}>
                                    <label style={{ fontSize: 16, fontWeight: '500', color: 'darkgray' }}>{startDispalyed}</label>
                                </button>}
                            />
                        </Grid>
                        <Grid item container xs={"auto"} justify="center" alignItems="center">
                            <DatePicker
                                selected={endDate}
                                onChange={date => endDateChange(date)}
                                customInput={<button className={classes.showDateButton}>
                                    <label style={{ fontSize: 16, fontWeight: '500', color: 'darkgray' }}>{endDispalyed}</label>
                                </button>}
                                withPortal
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                            />
                        </Grid>
                        {/* {(firstGroupValue === "temp") ? <DatePicker
                            minDate={startDate}
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            isClearable
                            inline
                        // onChange={date => setNextDate(date)}
                        /> : null} */}
                    </Grid> : <Grid container style={{ height: 200}}></Grid>}
                </Grid>
            </DialogContent>
            <DialogActions style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Button variant="contained" onClick={() => props.closeDialogFunction(false)} color="primary">
                    Cancel
          </Button>
                <Button variant="contained" onClick={() => handleClickAddGuest()} color="primary">
                    Add Guest
          </Button>
            </DialogActions>
            {/* <ToastComponent showToast={openAlert} onClose={closeAlertSuccess} onOpen={showAlertSuccess} /> */}
        </Dialog>
    )
}

AddGuestDialog.propTypes = {
    dialogState: PropTypes.bool.isRequired,
    closeDialogFunction: PropTypes.func.isRequired,
    refreshGuestList: PropTypes.func.isRequired,
    toastSuccess: PropTypes.func.isRequired,
    placeValues: PropTypes.object.isRequired
}
