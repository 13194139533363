export const enterTodayOptions = {
    search: false,
    print: false,
    filter: false,
    sort: false,
    download: false,
    customToolbar: null,
    selectableRows: "none",
    rowsPerPage: 10,
    pagination: false,
    viewColumns: false,
    textLabels: {
        body: {
            noMatch: 'There are no entrance for this day'
        }
    },
}