import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Area, ComposedChart, Line, ResponsiveContainer, XAxis, YAxis } from "recharts";
import LastCheckIn from '../../components/LastcheckinTable/LastCheckIn';
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Typography } from "../../components/Wrappers";
// context
import { db } from '../../context/Firebase';
// components
// styles
import useStyles from "./styles";
import moment from "moment-timezone";

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();
  var [visitToday, setVisitToday] = useState(0)
  var [enteredToday, setEnteredToday] = useState([])
  var [graphData, setGraphData] = useState([])


  function handleGuest(guestIDandTimestamp, nameAndPlate, residentName, Address, Phone) {
    var plate = nameAndPlate.substr(nameAndPlate.indexOf("@") + 1, nameAndPlate.length);
    var guestname = nameAndPlate.substr(0, nameAndPlate.indexOf("@"));
    var timestamp = guestIDandTimestamp.substr(guestIDandTimestamp.indexOf("@") + 1, guestIDandTimestamp.length)
    var timestampFormat = moment.tz(timestamp * 1000, "America/Los_Angeles").format("HH:mm")
    var guestid = guestIDandTimestamp.substr(0, guestIDandTimestamp.indexOf("@") - 1)
    return {
      "guestName": guestname,
      "residentName": residentName,
      "residentAddress": Address,
      "residentDisplayedPhone": makePhoneOk(Phone),
      "residentPhone": Phone,
      "timestamp": timestamp,
      "formatTimestamp": timestampFormat,
      "plate": plate,
      "guestid": guestid
    }
  }
  // local
  useEffect(() => {
    var returnArray = [];
    var counter = new Array(23).fill(0);
    var counterEnterToday = 0;
    const communityData = props.communityIdProp;
    db.ref('/' + communityData.placeType + '/' + communityData.stateID + '/' + communityData.placeID + '/databaseEnterToday/').once("value").then((values) => {
      const residentList = props.residentListMapProp;
      if (values.val() !== null && residentList !== undefined) {
        var snapshot = values.val();
        for (var houseID in snapshot) {
          for (var residentID in snapshot[houseID]) {
            if (residentList[residentID] !== undefined) {
              const residentName = residentList[residentID].fullname;
              const residentAddress = residentList[residentID].address;
              const residentPhone = residentList[residentID].phone;
              if(residentName !== undefined && residentAddress !== undefined) {
                for (var guestID in snapshot[houseID][residentID]) {
                  const currentguest = handleGuest(guestID, snapshot[houseID][residentID][guestID].guestvalue, residentName, residentAddress, residentPhone)
                  var hours = parseInt(moment.tz(parseInt(guestID.substr(guestID.indexOf("@") + 1, guestID.length)) * 1000, communityData.timezone).format("HH"));
                  counter[hours]++;
                  counterEnterToday++;
                  returnArray.push(currentguest)
                }
              }
            }
          }
        }
        var data = [];
        for (let i = 0; i < counter.length; i++) {
          var object = {
            hour: i,
            count: counter[i]
          }
          data.push(object);
        }
        setVisitToday(counterEnterToday)
        setGraphData(data)
        setEnteredToday(returnArray.sort((a, b) => b['timestamp'] - a['timestamp']).slice(0,10))
        return;
      }
    }).catch((error) => {
      console.log(error)
      console.log("Error fetching Enter Today")
      return;
    })
  }, [props.residentListMapProp, props.communityIdProp])

  return (
    <>
      <PageTitle title="Dashboard" />
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Widget
            title="Visits Today"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
            <div className={classes.visitsNumberContainer}>
              <Typography size="xl" weight="medium">
                {visitToday}
              </Typography>
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
            </Grid>
          </Widget>
        </Grid>
        <Grid item xs={10}>
          <Widget
            bodyClass={classes.mainChartBody}
            disableWidgetMenu={true}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h5"
                  color="text"
                  colorBrightness="secondary"
                >
                  Daily Check-In's
                </Typography>
              </div>
            }
          >
            <ResponsiveContainer width="100%" minWidth={440} height={220}>
              <ComposedChart
                margin={{ top: 0, right: -2, left: -2, bottom: 0 }}
                data={graphData}
              >
                <YAxis
                  ticks={[0, 10, 25, 50]}
                  tick={{ fill: "grey", fontSize: 14 }}
                  stroke={"grey"}
                  tickLine={false}
                />
                <XAxis
                  tickFormatter={i => i + 1}
                  tick={{ fill: "grey", fontSize: 14 }}
                  stroke={"grey"}
                  tickLine={true}
                />
                <Area
                  type="natural"
                  dataKey="count"
                  fill="white"
                  strokeWidth={0}
                  activeDot={true}
                />
                <Line
                  type="linear"
                  dataKey="count"
                  stroke={theme.palette.warning.main}
                  strokeWidth={3}
                  dot={{
                    stroke: theme.palette.warning.dark,
                    strokeWidth: 3,
                    fill: theme.palette.warning.main,
                  }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget
            disableWidgetMenu={true}
            title="Entered Today"
            upperTitle
            noBodyPadding
            bodyClass={classes.tableWidget}
          >
            <LastCheckIn
              data={enteredToday}
            />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}

function makePhoneOk(phone) {
  if(phone === undefined || phone === null || String(phone).length < 10) return phone;
  return "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + "-" + phone.substring(6, 10)
}