import { Avatar, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper } from '@material-ui/core';
import { AccountCircle, AddCircle, Info, Phone } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import { TitleDivider } from "./TitleDivider";
import BlockIcon from '@material-ui/icons/Block';

const iconSize = 16;

export class InfoOldHouseList extends React.Component {
    openDialogInput = () => {
        if (this.props.type === "additionalPhones") {
            this.props.showDialogfunction("Add Phone", "Please enter a valid phone number", "additionalPhones", this.props.array)
        } else if (this.props.type === "otherResidents") {
            this.props.showDialogfunction("Add Resident", "Please enter a valid full name", "otherResidents", this.props.array)
        } else {
            this.props.showDialogfunction("Add Note", "Please enter a valid text", "notes", this.props.array)
        }
    }

    handleDeleteClick = (item) => {
        this.props.handleDeleteInfoClick(item.id, this.props.array, this.props.type)
    }


    render() {
        return (
            <Grid container item xs={this.props.gridSize} justify="center">
                <TitleDivider titleSize={"h6"} title={this.props.title} sideToTitleAdd={this.props.type}></TitleDivider>
                <Paper elevation={2} style={{ width: '100%', textAlign: 'left', maxHeight: 260, overflow: 'auto', height: 250 }}>
                    <List>
                        {this.props.array.map((values) => {
                            return (
                                <ListItem key={values.id}>
                                    {/* <ListItemAvatar> */}
                                    <Grid container alignItems="center" justify="center">
                                        <Grid container item xs={1} justify="center" alignItems="center">
                                            <Avatar className={this.props.classes.avatarStyle}>
                                                {(this.props.type === "additionalPhones") ? <Phone style={{ width: iconSize, height: iconSize }} /> : null}
                                                {(this.props.type === "otherResidents") ? <AccountCircle style={{ width: iconSize, height: iconSize }} /> : null}
                                                {(this.props.type === "notes") ? <Info style={{ width: iconSize, height: iconSize }} /> : null}
                                                {(this.props.type === "blockedGuests") ? <BlockIcon style={{ width: iconSize, height: iconSize }} /> : null}
                                            </Avatar>
                                        </Grid>
                                        <Grid container item xs={10} justify="center" alignItems="center" style={{ textAlign: 'center' }}>
                                            {(this.props.secondary) ?
                                                <ListItemText
                                                    classes={{ primary: this.props.classes.listItemTextPrimary, secondary: this.props.classes.listItemTextSecondry }}
                                                    primary={values.primary}
                                                    secondary={values.secondary}
                                                />
                                                :
                                                <ListItemText
                                                    classes={{ primary: this.props.classes.listItemTextPrimary }}
                                                    primary={values.primary}
                                                />
                                            }
                                        </Grid>
                                        <Grid container item xs={1} justify="center" alignItems="center">
                                            {this.props.editable ? <ListItemSecondaryAction>
                                                <IconButton size={'small'} edge="end" aria-label="delete" onClick={() => this.handleDeleteClick(values)}>
                                                    <DeleteIcon style={{ width: iconSize + 4, height: iconSize + 4 }} />
                                                </IconButton>
                                            </ListItemSecondaryAction> : null}
                                        </Grid>
                                    </Grid>
                                    {/* </ListItemAvatar> */}

                                </ListItem>
                            )
                        })}
                    </List>
                </Paper>
                {this.props.editable ? <IconButton className={this.props.classes.addDialog} onClick={() => this.openDialogInput()}>
                    <AddCircle />
                </IconButton> : null}
            </Grid>
        )
    }
}

InfoOldHouseList.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['notes', 'otherResidents', 'additionalPhones', 'blockedGuests']).isRequired,
    array: PropTypes.array.isRequired,
    gridSize: PropTypes.number.isRequired,
    editable: PropTypes.bool.isRequired
}