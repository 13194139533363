import { Button, CircularProgress, Container, Grid } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// Styles
import React, { useState } from "react";
import { functions } from "../../../context/Firebase";
// components



export default function SendEmail(props) {
    var [feedbackContext, setFeedbackContext] = useState("");
    var [successSendEmail, showSuccessSendEmail] = useState(false);
    var [errorOccourSendEmail, showErrorOccurSendEmail] = useState(false);
    var [loadingSendEmail, setLoadingSendEmail] = useState(false);


    function sendEmail() {
        if (feedbackContext !== "") {
            const data = {
                message: feedbackContext,
                email: ".",
                emailType: "Feedback Email -",
                validateCode: "LM<N#$KV#Vsa",
                fullname: props.communityIdProp.placeID
            }
            var requestFunction = functions.httpsCallable('sendEmail')
            requestFunction(data)
                .then((e) => {
                    if (e.data.code === 200) {
                        setLoadingSendEmail(false)
                        showSuccessSendEmail(true)
                        setFeedbackContext("")
                    } else {
                        setLoadingSendEmail(false)
                        showErrorOccurSendEmail(true)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setLoadingSendEmail(false)
                    showErrorOccurSendEmail(true)
                })
        } else {
            return;
        }
    }

    return (
        <Container maxWidth="sm">
            <TextField
                required rows={2}
                rowsMax={10} label="Feedback" placeholder={"Type Here..."}
                defaultValue={feedbackContext}
                variant="outlined"
                style={{ width: 600 }}
                onChange={(e) => setFeedbackContext(e.target.value)}
                margin={'dense'} multiline fullWidth={true} />
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} container justify="center" alignItems="center">
                    {loadingSendEmail ? <CircularProgress style={{ margin: 15 }} /> : null}
                </Grid>
                <Grid item xs={12} container justify="center" alignItems="center">
                    {successSendEmail ? <Alert severity="success">Feedback has been sent!</Alert> : null}
                </Grid>
                <Grid item xs={12} container justify="center" alignItems="center">
                    {errorOccourSendEmail ? <Alert severity="error">Some error occurred</Alert> : null}
                </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
                <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={() => {
                    setLoadingSendEmail(true)
                    sendEmail()
                }}>
                    Send
        </Button>
            </Grid>
        </Container>
    )
}