import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paperContainer: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  textFieldEdit: {
    width: "100%"
  },
  table: {
    textAlign: "center",
  },
  tableHeader: {
    textAlign: "center",
    fontWeight: "bold"
  },
  resident: {
    padding: "5px"
  },
  containersBackground: {
    backgroundColor: "AliceBlue",
    borderRadius: "5px",
    margin: "5px"
  },
  textField: {
    borderRadius: "5px",
    width: "100%"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  plateButtons: {
    margin: "10px",
    marginBottom: "0",
    backgroundColor: "#0092b3",
    "&:hover": {
      backgroundColor: "#0092b3",
      opacity: "0.6"
    },
  },
  secondaryActionButton: {
    color: "white",
    backgroundColor: "#FCB308",
    "&:hover": {
      backgroundColor: "#FCB308",
      opacity: "0.6"
    },
  },
  checkButton: {
    color: "white",
    backgroundColor: "#00CC00",
    "&:hover": {
      backgroundColor: "#00CC00",
      opacity: "0.6"
    },
  },
  addDialog: {
    color: "#0092b3",
    "&:hover": {
      color: "#0092b3",
      opacity: "0.6"
    },
  },
  bottomButtons: {
    borderRadius: "5px",
    width: "100%",
    backgroundColor: "#0092b3",
    "&:hover": {
      backgroundColor: "#0092b3",
      opacity: "0.6"
    },
  },
  listItemTextPrimary: {
    fontSize: 12,
    color: 'black',
    fontWeight: '700'
  },
  listItemTextSecondry: {
    fontSize: 10,
    color: 'grey'
  },
  dialog: {
    color: "#0092b3"
  },
  input: {
    color: "#0092b3",
    
  },
  avatarStyle: {
    width: 24,
    height: 24,
    backgroundColor: "#0092b3",
  }
}))