import React from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
// import _ from 'lodash'

const columns = [
  {
    name: "guestname",
    label: "Guest Name",
  },
  {
    name: "createdAt",
    label: "Created At",
  },
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
      filter: false,
      sort: false
    }
  },
]

export class BlockedGuestsList extends React.PureComponent {
  render() {
    return (
      <Grid item xs={12}>
        <MUIDataTable
          data={this.props.data}
          columns={columns}
          options={{
            viewColumns: false,
            selectableRows: "none",
            rowsPerPage: 15,
          }}
        />
      </Grid>
    );
  }
}