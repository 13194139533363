import { Button, Container, Grid, IconButton, TextField, Typography, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import moment from 'moment';
import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import AdminPortalImage from '../../../../../assets/AdminDashboard.png';
import OpacityBackGround from '../../../../../images/OpacityBackGround.png';
//

// Images
import GuardAppImage from '../../../../../assets/GuardApp.png';
import Logo from '../../../../../assets/logo.png';
import blackLogo from '../../../../../assets/blacklogo.png';
import ResidentAppImage from '../../../../../assets/ResidentApp.png';
import useStyles from './styles';
import themes from '../../../../../themes';
import './styles.css';
import { functions } from '../../../../../context/Firebase';


const screenHeight = window.screen.height;
const PhoneLayout = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div style={{ backgroundImage: `url(${OpacityBackGround})`, width: '100%', height: screenHeight + 10, backgroundRepeat: 'no-repeat' }}>
        <Box display="flex" bgcolor="transparent" p={1} alignItems="center">
          <Link to="/" href="#" className={classes.menuButton}>
            <img alt="Liminal Gate" src={Logo} style={{ height: 78, width: 130, }} />
          </Link>
          <Box flexGrow={1} textAlign="right">
            <IconButton>
              {(localStorage.getItem("LoginInfo")) ?
                <Button color="secondary" component={Link} to="/app" className={classes.dashboardLoginButton}>
                  <Typography variant="h6" color="primary" aria-label="menu" className={classes.title}>
                    Dashboard
            </Typography>
                </Button>
                :
                <Button color="secondary" component={Link} to="/login" className={classes.dashboardLoginButton}>
                  <Typography variant="h6" color="primary" aria-label="menu" className={classes.title}>
                    Login
            </Typography>
                </Button>
              }
            </IconButton>
          </Box>
        </Box>
        <Container>
          <div className="secondContainer">
            <Slide direction="up" timeout={2200} in={true} mountOnEnter unmountOnExit>
              <Typography variant="h2" color="primary" aria-label="menu" className={classes.mainTitle}>
                A Modern Visitor Management System
          </Typography>
            </Slide>
          </div>
          <div className={classes.subtitleContainer}>
            <Container maxWidth={"md"} style={{ justifyContent: 'center' }}>
              <Slide direction="up" timeout={2600} in={true} mountOnEnter unmountOnExit>
                <Container className={classes.subtitleSlide}>
                  <Typography variant="h4" color="primary" aria-label="menu" className={classes.secondaryTitle}>
                    At Liminal, our visitor management software is the leading technology in the industry for managing access to gated communities.
              </Typography>
                </Container>
              </Slide>
              <Container className={classes.subtitleSlide}>
                <Slide direction="up" timeout={3200} in={true} mountOnEnter unmountOnExit>
                  <Typography variant="h4" color="primary" aria-label="menu" className={classes.secondaryTitle}>
                    Our mission is to bring exclusive communities into the future by providing ease of use for the residents & guards, without sacrificing security.
              </Typography>
                </Slide>
              </Container>
            </Container>
          </div>
        </Container>
      </div>
      <AboutUs />
      <div style={{ backgroundImage: `url(${OpacityBackGround})`, width: '100%', backgroundRepeat: 'no-repeat', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
        <Fade top Big>
          <Typography variant="h2" color="primary" aria-label="menu" className={classes.whiteTitle}>
            Resident App
          </Typography>
        </Fade>
        <Fade right>
          <ResidentAppDisplay />
        </Fade>
      </div>
      <div style={{ backgroundColor: "white", textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
        <Fade top Big>
          <Typography variant="h2" aria-label="menu" className={classes.blackTitle}>
            Guard Station Tablet
          </Typography>
        </Fade>
        <Fade left>
          <GuardAppDisplay />
        </Fade>
      </div>
      <div style={{ backgroundImage: `url(${OpacityBackGround})`, width: '100%', backgroundRepeat: 'no-repeat', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
        <Fade top Big>
          <Typography variant="h2" color="primary" aria-label="menu" className={classes.whiteTitle}>
            Community Manager Web Portal
          </Typography>
        </Fade>
        <Fade right>
          <WebportalDisplay />
        </Fade>
        <Fade bottom>
          <ContactUs />
        </Fade>
      </div>
      <div style={{ width: '100%', backgroundColor: "#87CEEB", height: 45, justifyContent: "center", textAlign: 'center', flexDirection: 'row', display: 'flex' }}>
        <Typography variant="h4" color="primary" aria-label="menu" className={classes.footerText} align="center">
          © {moment().format("yyyy")} Liminal
        </Typography>
        <Link to="/termsofservices" style={{ marginLeft: 10, color: themes.default.palette.primary.main }}>
          Terms Of Services
        </Link>
      </div>
    </div>
  )
}
export default PhoneLayout;

const ResidentAppDisplay = () => {
  const classes = useStyles();
  return (
    <Box style={{ width: '100%', alignItems: 'center', marginTop: 30, marginBottom: 30 }}>
      <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <div style={{ width: '90%', maxWidth: 340 }}>
          <Typography variant="h6" color="primary" className={classes.contextMediaTextWhite}>
            Residents can add, delete, and manage their guest list directly through our mobile app and be notified when a guest arrives.
          </Typography>
        </div>
        <img alt="" src={ResidentAppImage} className="image" />
      </div>
    </Box>
  )
}
const WebportalDisplay = ({ props }) => {
  const classes = useStyles();
  return (
    <Box style={{ width: '100%', alignItems: 'center', marginTop: 30, marginBottom: 30 }}>
      <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <div style={{ width: '90%', maxWidth: 340 }}>
          <Typography variant="h6" color="primary" className={classes.contextMediaTextWhite}>
            Administrative staff can view & manage data from each community and get comprehensive reports of resident info, guest entrances, and more.
          </Typography>
        </div>
        <img alt="" src={AdminPortalImage} className="imageDash" />
      </div>
    </Box>
  )
}

const GuardAppDisplay = ({ props }) => {
  const classes = useStyles();
  return (
    <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', marginTop: 30, marginBottom: 30 }}>
      <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <img alt="" src={GuardAppImage} className="image" />
        <div style={{ width: 340 }}>
          <Typography variant="h6" color="primary" className={classes.contextMediaTextBlue}>
            Guards can seamlessly check on guests, search through resident profiles and view entrance history.
          </Typography>
        </div>
      </div>
    </Box>
  )
}

const AboutUs = () => {
  const classes = useStyles();
  return (
    <Box style={{ backgroundColor: 'white', textAlign: 'center', width: '100%' }}>
      <Grid container xs={12} direction="row">
        <Grid item xs={6} style={{ backgroundColor: "#F0F8FF" }}>
          <Fade left>
            <Typography variant="h2" color="primary" aria-label="menu" className={classes.blackTitle}>
              About Us
            </Typography>
            <Typography variant="h4" color="primary" aria-label="menu" className={classes.aboutUsParagraph}>
              At Liminal, we pride ourselves on providing excellent service and peace of mind to the residents living at any community we service
            </Typography>
            <Typography variant="h4" color="primary" aria-label="menu" className={classes.aboutUsParagraph}>
              We make products that are helpful for your day-to-day life and easy to use. With communities subscribing to our Liminal software, residents can easily manage their active guests and view entrance history all through our mobile app. Available on iOS & Android.
            </Typography>
            <Typography variant="h4" color="primary" aria-label="menu" className={classes.aboutUsParagraph}>
              Our team of engineers worked closely with several gated communities to find out exactly what kind of software and features will be the most useful for the day-to-day life of residents, guards, and administrative staff. After a long time of hard work, our visitor management software is the leading technology in the industry for managing access to gated communities.
            </Typography>
            <Typography variant="h4" color="primary" aria-label="menu" className={classes.aboutUsParagraph}>
              Our founders realized the way most gated communities operate is extremely old-school and hasn’t caught up to today’s technology. That’s what motivated them to begin bringing exclusive neighborhoods into modern times. Today, we keep that same mission at the heart of everything we do.
            </Typography>
          </Fade>
        </Grid>
        <Grid container item xs={6} justifyContent style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Fade right>
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <img alt="Liminal Gate" src={blackLogo} style={{ width: "100%", backgroundSize: "resize" }} />
            </div>
          </Fade>
        </Grid>
      </Grid>
    </Box>
  )
}

const ContactUs = () => {
  const classes = useStyles();
  const [fullnameState, setFullName] = useState("")
  const [emailState, setEmail] = useState("")
  const [messageState, setMessage] = useState("")

  var [successSendEmail, showSuccessSendEmail] = useState(false);
  var [errorOccurEmail, showErrorSendEmail] = useState(false);
  var [loadingSendEmail, setLoadingSendEmail] = useState(false);


  function sendMailContactUs() {
    if (messageState === "" || fullnameState === "" || messageState === "") {
      alert("Please fill all the info")
    } else {
      const data = {
        message: messageState,
        email: emailState,
        emailType: "ContactUs Email -",
        validateCode: "LM<N#$KV#Vsa",
        fullname: fullnameState
      }
      var requestFunction = functions.httpsCallable('sendEmail')
      requestFunction(data).then((res) => {
        if (res.status === 200) {
          setLoadingSendEmail(false)
          setMessage("")
          setEmail("")
          setFullName("")
          showSuccessSendEmail("")
          showSuccessSendEmail(true)
        } else {
          setLoadingSendEmail(false)
          setMessage("")
          setEmail("")
          setFullName("")
          showSuccessSendEmail("")
          showErrorSendEmail("")
        }
      }).catch((error) => {
        setLoadingSendEmail(false)
        setMessage("")
        setEmail("")
        setFullName("")
        showErrorSendEmail("")
        console.log(error)
      })
    }
  }
  return (
    <Box style={{ backgroundColor: 'white', textAlign: 'center', width: '100%', marginTop: 10 }}>
      <Container>
        <Typography variant="h2" color="primary" aria-label="menu" className={classes.blueTitle}>
          Contact Us
      </Typography>
        <Container style={{ marginTop: 10, marginBottom: 40 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField variant="filled" label="Full Name" defaultValue={fullnameState} onChange={(e) => setFullName(e.target.value)} style={{ maxWidth: 300, width: '100%' }} />
            </Grid>
            <Grid item xs={12}>
              <TextField variant="filled" label="Email" defaultValue={emailState} onChange={(e) => setEmail(e.target.value)} style={{ maxWidth: 300, width: '100%' }} />
            </Grid>
            <Grid item xs={12}>
              <TextField multiline rows={5} variant="filled" defaultValue={messageState} onChange={(e) => setMessage(e.target.value)} label="Message" style={{ maxWidth: 300, width: '100%' }} />
            </Grid>
            <Grid item xs={12}>
              <Container>
                <Grid item xs={12} container justify="center" alignItems="center">
                  {loadingSendEmail ? <CircularProgress style={{ margin: 15 }} /> : null}
                </Grid>
                <Grid item xs={12} container justify="center" alignItems="center">
                  {successSendEmail ? <Alert severity="success">Feedback has been sent!</Alert> : null}
                </Grid>
                <Grid item xs={12} container justify="center" alignItems="center">
                  {errorOccurEmail ? <Alert severity="error">Some error occurred!</Alert> : null}
                </Grid>
              </Container>
              <Button onClick={() => {
                setLoadingSendEmail(true)
                sendMailContactUs()
              }} color="primary" variant="contained">Send</Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Box>
  )
}