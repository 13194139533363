import { Button, Container, Grid, IconButton, TextField, Typography, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import moment from 'moment';
import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import AdminPortalImage from '../../../../../assets/AdminDashboard.png';
//

// Images
import GuardAppImage from '../../../../../assets/GuardApp.png';
import Logo from '../../../../../assets/logo.png';
import blackLogo from '../../../../../assets/blacklogo.png';
import ResidentAppImage from '../../../../../assets/ResidentApp.png';

import OpacityBackGround from '../../../../../images/OpacityBackGround.png';

import useStyles from './styles';
import './styles.css';
import themes from '../../../../../themes';
import { functions } from '../../../../../context/Firebase';

const STATIC_CONTENT = {
  RESIDENT_APP_CONTENT: 'Residents can add, delete, and manage their guest list directly through our mobile app and be notified when a guest arrives.',
  GUARD_APP_CONTENT: 'Guards can seamlessly check on guests, search through resident profiles and view entrance history.',
  WEBSITE_APP_CONTENT: 'Administrative staff can view & manage data from each community and get comprehensive reports of resident info, guest entrances, and more.',
}
const secondaryTitlesVariant = "h2"
const textVariant = "h6"
const screenHeight = window.screen.height;

const DesktopLayout = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div style={{ backgroundImage: `url(${OpacityBackGround})`, height: screenHeight, backgroundRepeat: 'no-repeat',backgroundSize:"cover"}}>
        <Box display="flex" bgcolor="transparent" p={1} alignItems="center">
          <Link to="/" href="#" className={classes.menuButton}>
            <img alt="Liminal Gate" src={Logo} style={{ height: 90, width: 150, }} />
          </Link>
          <Box flexGrow={1} textAlign="right">
            <IconButton>
              {(localStorage.getItem("LoginInfo")) ?
                <Button className={classes.dashboardLoginButton} color="secondary" component={Link} to="/app">
                  <Typography variant={textVariant} color="primary" aria-label="menu" className={classes.title}>
                    Dashboard
                  </Typography>
                </Button>
                :
                <Button className={classes.dashboardLoginButton} color="secondary" component={Link} to="/login">
                  <Typography variant="h6" color="primary" aria-label="menu" className={classes.title}>
                    Login
                  </Typography>
                </Button>
              }
            </IconButton>
          </Box>
        </Box>
        <hr style={{ color: '#e6faff', borderColor: '#e6faff', opacity: '0.5' }}></hr>
        <Container>
          <div className="secondContainer">
            <Slide direction="up" timeout={2200} in={true} mountOnEnter unmountOnExit>
              <Typography variant="h1" color="primary" aria-label="menu" className={classes.mainTitle}>
                A Modern Visitor Management System
          </Typography>
            </Slide>
          </div>
          <div className={classes.subtitleContainer}>
            <Container maxWidth={"md"} style={{ justifyContent: 'center' }}>
              <Slide direction="up" timeout={2600} in={true} mountOnEnter unmountOnExit>
                <Container className={classes.subtitleSlide}>
                  <Typography variant={textVariant} color="primary" aria-label="menu" className={classes.secondaryTitle}>
                    At Liminal, our visitor management software is the leading technology in the industry for managing access to gated communities.
              </Typography>
                </Container>
              </Slide>
              <Container className={classes.subtitleSlide}>
                <Slide direction="up" timeout={3200} in={true} mountOnEnter unmountOnExit>
                  <Typography variant={textVariant} color="primary" aria-label="menu" className={classes.secondaryTitle}>
                    Our mission is to bring exclusive communities into the future by providing ease of use for the residents & guards, without sacrificing security.
              </Typography>
                </Slide>
              </Container>
            </Container>
          </div>
        </Container>
      </div>
      <AboutUs />
      <HeroContainer classes={classes} title={'Resident App'} content={STATIC_CONTENT.RESIDENT_APP_CONTENT} type={'image'} picture={ResidentAppImage}/>
      <HeroContainer classes={classes} title={'Guard App'} content={STATIC_CONTENT.GUARD_APP_CONTENT} type={'blank'} picture={GuardAppImage}/>
      <HeroContainer classes={classes} title={'Community Manager Web Portal'} content={STATIC_CONTENT.WEBSITE_APP_CONTENT} type={'image'} picture={AdminPortalImage}/>
      <Fade bottom>
        <ContactUs />
      </Fade>
      <div style={{ width: '100%', backgroundColor: "#F0F8FF", height: 45, justifyContent: "center", textAlign: 'center', flexDirection: 'row', display: 'flex' }}>
        <Typography variant={textVariant} color="primary" aria-label="menu" className={classes.footerText} align="center">
          © {moment().format("yyyy")} Liminal
        </Typography>
        <Link to="/termsofservices" style={{ marginLeft: 10, color: themes.default.palette.primary.main }}>
          Terms Of Services
        </Link>
      </div>
    </div>
  )
}
export default DesktopLayout;

const HeroContainer = ({ classes, title, content, type, picture}) => {
  const titleStyle = type === 'image' ? classes.whiteTitle : classes.blackTitle
  const contextStyle = type === 'image' ? classes.contextMediaTextWhite : classes.contextMediaTextBlue
  const defaultStyleDiv = {backgroundSize:"cover",textAlign: 'center', minHeight: 300, paddingTop: 20, paddingBottom: 20}
  const backgroundStyle = type === 'image' ? 
    {backgroundImage: `url(${OpacityBackGround})`, backgroundRepeat: 'no-repeat',...defaultStyleDiv} : 
    {backgroundColor: "#F0F8FF",...defaultStyleDiv }
  const contentCondition = type === 'image' ? 'row' : 'row-reverse'
  return(
    <div style={backgroundStyle}>
      <Fade top Big>
        <Typography variant={secondaryTitlesVariant} className={titleStyle}>
          {title}
        </Typography>
      </Fade>
      <Fade left={type === 'image'} right={type !== 'image'}>
      <div style={{display: 'flex', flexDirection: contentCondition}}>
        <div style={{flex: 1, display:'flex', justifyContent:'center', paddingTop: 20}}>
          <img alt="" src={picture} className="image" />
        </div>
        <div style={{flex: 2, display:'flex', justifyContent:'center', paddingTop: 20, alignItems:'center'}}>
          <div style={{width: 400, textAlign:'center'}}>
            <Typography variant={textVariant} color="primary" className={contextStyle}>
              {content}
            </Typography>
          </div>
        </div>
      </div>
      </Fade>
    </div>
  )
}

const AboutUs = () => {
  const classes = useStyles();
  return (
    <Box style={{ textAlign: 'center', width: '100%' }}>
      <Grid container direction="row">
        <Grid item xs={9} style={{ backgroundColor: "#F0F8FF" }}>
          <Fade left>
            <Typography variant={secondaryTitlesVariant} aria-label="menu" className={classes.blackTitle}>
              About Us
          </Typography>
            <Typography variant={textVariant} className={classes.aboutUsParagraph}>
              At Liminal, we pride ourselves on providing excellent service and peace of mind to the residents living at any community we service
            </Typography>
            <Typography variant={textVariant} className={classes.aboutUsParagraph}>
              We make products that are helpful for your day-to-day life and easy to use. With communities subscribing to our Liminal software, residents can easily manage their active guests and view entrance history all through our mobile app. Available on iOS & Android.
            </Typography>
            <Typography variant={textVariant} className={classes.aboutUsParagraph}>
              Our team of engineers worked closely with several gated communities to find out exactly what kind of software and features will be the most useful for the day-to-day life of residents, guards, and administrative staff. After a long time of hard work, our visitor management software is the leading technology in the industry for managing access to gated communities.
            </Typography>
            <Typography variant={textVariant} className={classes.aboutUsParagraph}>
              Our founders realized the way most gated communities operate is extremely old-school and hasn’t caught up to today’s technology. That’s what motivated them to begin bringing exclusive neighborhoods into modern times. Today, we keep that same mission at the heart of everything we do.
            </Typography>
          </Fade>
        </Grid>
        <Grid container item xs={3} style={{ justifycontent: 'center', alignItems: 'center', backgroundColor: "#87CEEB", opacity: "0.7" }}>
          <Fade right>
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <img alt="Liminal Gate" src={blackLogo} style={{ width: "100%", backgroundSize: "resize" }} />
            </div>
          </Fade>
        </Grid>
      </Grid>
    </Box>
  )
}

const ContactUs = () => {
  const classes = useStyles();
  const [fullnameState, setFullName] = useState("")
  const [emailState, setEmail] = useState("")
  const [messageState, setMessage] = useState("")

  var [successSendEmail, showSuccessSendEmail] = useState(false);
  var [errorOccurEmail, showErrorSendEmail] = useState(false);
  var [loadingSendEmail, setLoadingSendEmail] = useState(false);


  function sendMailContactUs() {
    if (messageState === "" || fullnameState === "" || messageState === "") {
      alert("Please fill all the info")
    } else {
      setLoadingSendEmail(true)
      const data = {
        message: messageState,
        email: emailState,
        emailType: "ContactUs Email -",
        validateCode: "LM<N#$KV#Vsa",
        fullname: fullnameState
      }
      var requestFunction = functions.httpsCallable('sendEmail')
      requestFunction(data).then((res) => {
        if (res.status === 200) {
          setLoadingSendEmail(false)
          setMessage("")
          setEmail("")
          setFullName("")
          showSuccessSendEmail(true)
        } else {
          setLoadingSendEmail(false)
          setMessage("")
          setEmail("")
          setFullName("")
          showSuccessSendEmail(true)
          showErrorSendEmail("")
        }
      }).catch((error) => {
        setLoadingSendEmail(false)
        setMessage("")
        setEmail("")
        setFullName("")
        showErrorSendEmail(true)
        console.log(error)
      })
    }
  }
  return (
    <Box style={{ backgroundColor: 'white', textAlign: 'center', width: '100%', marginTop: 10 }}>
      <Container>
        <Typography variant={secondaryTitlesVariant} aria-label="menu" className={classes.blackTitle}>
          Contact Us
      </Typography>
        <Container style={{ marginTop: 10, marginBottom: 40 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField variant="filled" label="Full Name" defaultValue={fullnameState} onChange={(e) => setFullName(e.target.value)} style={{ width: 400 }} />
            </Grid>
            <Grid item xs={12}>
              <TextField variant="filled" label="Email" defaultValue={emailState} onChange={(e) => setEmail(e.target.value)} style={{ width: 400 }} />
            </Grid>
            <Grid item xs={12}>
              <TextField multiline rows={5} variant="filled" defaultValue={messageState} onChange={(e) => setMessage(e.target.value)} label="Message" style={{ width: 400 }} />
            </Grid>
            <Grid item xs={12}>
              <Container>
                <Grid item xs={12} container justify="center" alignItems="center">
                  {loadingSendEmail ? <CircularProgress style={{ margin: 15 }} /> : null}
                </Grid>
                <Grid item xs={12} container justify="center" alignItems="center">
                  {successSendEmail ? <Alert severity="success">Feedback has been sent!</Alert> : null}
                </Grid>
                <Grid item xs={12} container justify="center" alignItems="center">
                  {errorOccurEmail ? <Alert severity="error">Some error occurred</Alert> : null}
                </Grid>
              </Container>
              <Button onClick={() => {
                sendMailContactUs()
              }} color="primary" variant="contained">Send</Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Box>
  )
}