import { Grid, TextField } from "@material-ui/core";
import React from "react";
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from "@material-ui/core/Tooltip";

export class EditableDetails extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            detailValue: this.props.name,
            holderLastValue: ""
        };
    }

    handleChangeText = (event) => {
        this.setState({
            detailValue: event.target.value,
        });
    };

    handleEditClick = () => {
        this.setState({ disabled: false, holderLastValue: this.state.detailValue })
    }

    handleCheckClick = async () => {
        const promiseResponse = await this.props.handleEditChange(this.state.detailValue, this.state.holderLastValue)
        this.setState({ disabled: true, detailValue: promiseResponse })
        return;
    };

    render() {
        return (
            <Grid container item xs={4} direction="row" alignItems="center">
                <Grid item xs={10}>
                    <TextField
                        id={this.props.id}
                        label={this.props.label}
                        value={this.state.detailValue}
                        onChange={this.handleChangeText}
                        variant="outlined"
                        disabled={this.state.disabled}
                        className={this.props.classes.textFieldEdit}
                    />
                </Grid>
                <Grid item container xs={2} justify="center" alignContent="center" alignItems="center">
                    {this.state.disabled ?
                        <Tooltip title={this.props.toolTip} aria-label="msg">
                            <Fab className={this.props.classes.secondaryActionButton} size="small" color="secondary" aria-label="edit" onClick={this.handleEditClick}>
                                <EditIcon />
                            </Fab>
                        </Tooltip>
                        :
                        <Tooltip title="Verify change" aria-label="msg">
                            <Fab className={this.props.classes.checkButton} size="small" aria-label="check" onClick={this.handleCheckClick}>
                                <CheckIcon />
                            </Fab>
                        </Tooltip>
                    }
                </Grid>
            </Grid>
        );
    }
}

