import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    outlinedChosen: {
        // backgroundColor: theme.palette.primary.main,
        borderRadius: 30,
    },
    showDateButton: {
        height: 50,
        borderRadius: 30,
        fontSize: 15,
        shadowRadius: 15,
        color: '#c6c6c6',
        backgroundColor: '#e9e9e9',
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        elevation: 3,
        textAlign: 'center',
        fontFamily: 'Montserrat-Black',
        justifyContent: 'center',
        width: 260,
        marginVertical: 8,
    },

}));


