
export const enterTodayColumns = [
  {
    name: "guestName",
    label: "Guest Name",
    options: {
      filter: false,
      sort: false,
      rowStyle: {
        fontSize: 9      
      }
    }
  },
  {
    name: "residentName",
    label: "Resident Name",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "residentPhone",
    label: "Resident Phone",
    options: {
      filter: false,
      display: false,
      sort: false,
    }
  },
  {
    name: "residentDisplayedPhone",
    label: "Resident Phone",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "residentAddress",
    label: "Resident Address",
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: "formatTimestamp",
    label: "Time Entered",
    options: {
      filter: false,
      sort: false,
      display: "true",
    }
  },
  {
    name: "timestamp",
    options: {
      filter: false,
      sort: false,
      display: "false",
    }
  },
  {
    name: "plate",
    label: "License Plate",
    options: {
      filter: false,
      sort: false,
    }
  },
];


export const historyColumns = [
  {
    name: "id",
    options: {
      display: false,
      filter: false,
      viewColumns: false,
      sort: false
    }
  },
  {
    name: "guestName",
    label: "Guest Name",
    options: {
      display: true,
      filter: false,
    }
  },
  {
    name: "formatTimestamp",
    label: "Time Enter"
  },
  {
    name: "residentAddress",
    label: "Resident Address",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "residentName",
    label: "Resident Name",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "plate",
    label: "License Number"
  },
]


export const historyColumnsResident = [
  {
    name: "id",
    options: {
      display: false,
      filter: false,
      viewColumns: false,
      sort: false
    }
  },
  {
    name: "guestName",
    label: "Guest Name",
    options: {
      display: true,
      filter: false,
    }
  },
  {
    name: "formatTimestamp",
    label: "Time Enter"
  },
  {
    name: "residentName",
    label: "Resident Name",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "plate",
    label: "License Number"
  },
]