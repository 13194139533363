import {
  AppBar,
  IconButton,
  Menu, Toolbar
} from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon, Menu as MenuIcon,


  Person as AccountIcon
} from "@material-ui/icons";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
// context

import {
  toggleSidebar, useLayoutDispatch, useLayoutState
} from "../../context/LayoutContext";
import { signOut, useUserDispatch } from "../../context/UserContext";
// components
import { Typography } from "../Wrappers/Wrappers";
// styles
import useStyles from "./styles";
import CommunityList from './CommunityList'



export default function Header(props) {
  var classes = useStyles();
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  const [CommunityListState, setCommunityList] = useState([])
  // local
  var [profileMenu, setProfileMenu] = useState(null);

  useEffect(() => {
    if (props.CommunityList !== undefined && props.CommunityList !== null) {
      setCommunityList(props.CommunityList)
    }
  }, [props.CommunityList])

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
              <MenuIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }}
              />
            )}
        </IconButton>
        {(CommunityListState.length > 0) ?
          <Typography weight="medium" size="md" className={classes.logotypeWithName}>
            Welcome {props.displayedName} !
          </Typography>
          :
          <Typography variant="h6" weight="medium" className={classes.logotype}>
            Liminal Dashboard
            </Typography>}

        {(CommunityListState.length > 0) ?
          <CommunityList
            dispatch={userDispatch}
            CommunityList={CommunityListState}
            history={props.history}
            LoggedObject={props.LoggedObject}
            classes={classes}
          /> : null}
        <div className={classes.grow} />

        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => handlesignout(userDispatch, props.history)}
            >
              Sign Out
              </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

function handlesignout(userDispatch, history) {
  localStorage.clear();
  signOut(userDispatch, history)
}
