import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import { withRouter } from 'react-router';
import PageTitle from "../../components/PageTitle/PageTitle";

function ResidentList(props) {
  const [residentList] = useState(() => fromMapToArray(props.residentListMapProp))
  const residentListColumns = [
    {
      name: "userid",
      options: {
        display: false,
        filter: false,
        viewColumns: false
      }
    },
    {
      name: "Resident Name",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "email",
      options: {
        display: false,
        filter: false
      }
    },
    {
      name: "PhoneNumber",
      label: "Phone Number",
      options: {
        display: true,
        filter: false,
      }
    },
    {
      name: "residentHidePhone",
      options: {
        sort: false,
        filter: false,
        display: false,
        viewColumns: false
      }
    },
    {
      name: "Address",
      options: {
        sort: false,
        filter: false
      }
    }
  ]
  async function onRowClick(rowData, rowMeta) {
    var residentID = rowData[0];
    props.history.push("/app/displayresident/" + residentID)
  }
  return (
    <>
      <PageTitle title={"Resident List"} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MUIDataTable
            title=""
            data={residentList}
            columns={residentListColumns}
            options={{
              search: true,
              print: true,
              filter: false,
              viewColumns: true,
              selectableRows: "none",
              selectToolbarPlacement: "none",
              onRowClick: onRowClick,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}


export default withRouter(ResidentList);

function fromMapToArray(map) {
  const returnArray = []
  for(const residentID in map) {
    returnArray.push([
      residentID,
      map[residentID]['fullname'],
      map[residentID]['email'],
      makePhoneOk(map[residentID]['phone']),
      map[residentID]['phone'],
      map[residentID]['address'],
    ])
  }
  return returnArray;
}

function makePhoneOk(phone) {
  if (phone === undefined || phone === null || String(phone).length < 10) return phone;
  return "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + "-" + phone.substring(6, 10)
}
