import { Button, Grid, Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from "moment-timezone";
import MUIDataTable from "mui-datatables";
import React, {
    useCallback,
    // useEffect, 
    useState
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PageTitle from "../../../components/PageTitle/PageTitle";
// components
import { historyColumnsResident } from '../../../config/columns';
import {
    // db, 
    firestore
} from '../../../context/Firebase';
// import DateFnsUtils from '@date-io/date-fns'

const yesterday = new Date()
yesterday.setDate(yesterday.getDate() - 1)

export default function History(props) {
    const [EntraceHistory, setEntraceHistory] = useState([])

    const [communityData] = useState(props.LoggedObject)
    const [houseInfo] = useState(props.houseInfo)

    const [CurrentDate, setCurrentDate] = useState(new Date().toDateString())
    const [maxDate] = useState(yesterday)
    const [NextDate, setNextDate] = useState(new Date())

    function fetchHistoryDocuemnt() {
        let NextDateString = moment.tz(NextDate, communityData.timezone).format("MMDDYYYY")
        if (NextDate !== null && NextDate !== undefined) {
            var returnArray = [];
            firestore.collection(communityData.placeType).doc(communityData.placeID).collection("historyEntrance").doc(NextDateString).get()
                .then((values) => {
                    if (values.data() !== null && values.data() !== undefined) {
                        if (values.data()[props.LoggedObject.houseID] !== undefined && values.data()[props.LoggedObject.houseID] !== null) {
                            const Snapshot = values.data();
                            const houseResidents = houseInfo.houseResidentWhoSignedUp;
                            for (const residentID in houseResidents) {
                                if (Snapshot[residentID] !== undefined) {
                                    for (const guestIDandTime in Snapshot[residentID]) {
                                        const currentGuest = handleGuest(guestIDandTime, Snapshot[residentID][guestIDandTime].guestvalue, Snapshot[residentID][guestIDandTime].type,houseResidents[residentID]['residentName'], props.LoggedObject.timezone)
                                        returnArray.push(currentGuest)
                                    }
                                }
                            }
                            const sortedArray = _.orderBy(returnArray, ['timestamp'], ['desc'])
                            setEntraceHistory(sortedArray)
                            setCurrentDate(NextDate.toDateString())
                            return;
                        } else {
                            setEntraceHistory([])
                            setCurrentDate(NextDate.toDateString())
                        }
                    } else {
                        setEntraceHistory([])
                        setCurrentDate(NextDate.toDateString())
                        return;
                    }
                }).catch((error) => {
                    console.log(error)
                    setEntraceHistory([])
                    setCurrentDate(NextDate.toDateString())
                    return;
                })
        }
    }

    const handleGuest = useCallback((guestIDandTimestamp, nameAndPlate, guestType, residentName, timezone) => {
        const stringfyNameAndPlate = String(nameAndPlate)
        const stringfyGuestIDAndTimestamp = String(guestIDandTimestamp)
        var plate = stringfyNameAndPlate.substr(stringfyNameAndPlate.indexOf("@") + 1, stringfyNameAndPlate.length);
        var guestname = stringfyNameAndPlate.substr(0, stringfyNameAndPlate.indexOf("@"));
        var timestamp = stringfyGuestIDAndTimestamp.substr(stringfyGuestIDAndTimestamp.indexOf("@") + 1, stringfyGuestIDAndTimestamp.length)
        var timestampFormat = moment.tz(timestamp * 1000, timezone).format("HH:mm")
        var guestid = stringfyGuestIDAndTimestamp.substr(0, stringfyGuestIDAndTimestamp.indexOf("@") - 1)
        return {
            "guestName": guestname,
            "residentName": residentName,
            "formatTimestamp": timestampFormat,
            "plate": plate,
            "guestType": guestType,
            "timestamp": timestamp,
            "guestid": guestid
        }
    }, [])
    const classes = useStyles();
    return (
        <>
            <PageTitle title={"Entrance History"} />
            <div className={classes.root}>
                <Paper className={classes.paper3}>
                    <div style={{ justifyContent: 'center', alignContent: 'center', margin: 10 }}>
                        <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', margin: 10 }}>
                            <DatePicker
                                selected={NextDate}
                                maxDate={maxDate}
                                inline
                                isClearable
                                popperPlacement="right-end"
                                onChange={date => setNextDate(date)}
                            />
                            {/* </div> */}
                        </div>
                    </div>
                    <div style={{ margin: 10 }}>
                        <Button variant="contained" color="primary" onClick={() => fetchHistoryDocuemnt()}>
                            Get History
              </Button>
                    </div>
                </Paper>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MUIDataTable
                        title={CurrentDate}
                        rowsPerPage={25}
                        data={EntraceHistory}
                        viewColumns={false}
                        columns={historyColumnsResident} //"Time Entered"
                        options={{
                            textLabels: {
                                body: {
                                    noMatch: 'There are no entrance for this day'
                                }
                            },
                            selectableRows: "none",
                            filter: false,
                            sort: false,
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        alignItems: 'center',
        textAlign: 'center',
        height: 348,
        justifyItems: 'center',
        justifyContent: 'center',
    },
    paper2: {
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: 120,
        paddingBottom: 120,
    },
    paper3: {
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: 20,
        paddingBottom: 20,
        marginBottom: 20,
        width: '100%'
    },
}));
