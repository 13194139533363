import { Button, CircularProgress, Grid, IconButton, Paper } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import Snackbar from '@material-ui/core/Snackbar';
// Dialog
import TextField from '@material-ui/core/TextField';
import Tooltip from "@material-ui/core/Tooltip";
import { AddCircle } from '@material-ui/icons';
import MessageIcon from '@material-ui/icons/Message';
import MuiAlert from '@material-ui/lab/Alert';
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { firestore } from "../../../context/Firebase";
import { useUserState } from '../../../context/UserContext';
import { addBlockedGuestFunction, addHouseData, createEditInputRequestObject, createEmailSendingHouseIdObject, deleteCarFromDatabase, deleteHouseData, getResidentObject, httpRequestEditUserInfo, pushNewPlateToDatabase } from '../Functions';
import { BlockedGuestsList } from "./BlockedGuestsList";
import { EditableDetails } from "./EditableDetails";
import { GuestsList } from "./GuestsList";
import { InfoOldHouseList } from "./InfoOldHouseList";
// import { NonEditableDetails } from "./NonEditableDetails";
import { PageBottomButtons } from "./PageBottomButtons";
import { PlatesTable } from "./PlatesTable";
import useStyles from "./styles";
import { TitleDivider } from "./TitleDivider";


// End

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ResidentScreen(props) {

    const classes = useStyles();
    const communityData = useUserState();

    const [residentInfo] = useState(props.residentObject)
    const [residentName] = useState(props.residentObject.userInfo.residentName)
    const [residentEmail, setResidentEmail] = useState(props.residentObject.userInfo.email)
    const [residentPhone] = useState(props.residentObject.userInfo.phone)

    const [plates, setPlates] = useState(props.residentObject.houseInfo.plates)

    const [oldResidentsArray, setOldResidentsArray] = useState(props.residentObject.houseInfo.oldResidentArray)
    const [oldPhonesArray, setOldPhonesArray] = useState(props.residentObject.houseInfo.oldPhonesArray)
    const [notesArray, setNotesArray] = useState(props.residentObject.houseInfo.notes)

    const [isMessageEnabled] = useState(props.residentObject.userInfo.pushNotification)
    const [visibleGuests, setVisibleGuests] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // Alert
    const [successAlert, setSuccessAlert] = React.useState(false);
    const [errorAlert, setErrorAlert] = React.useState(false);
    const [errorMessageState, setErrorMessageState] = useState("Some error occurred!!")
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        successAlert ? setSuccessAlert(false) : setErrorAlert(false);
    };

    // Dialog 
    const [dialogInputState, setDialogInput] = useState(false)
    const [dialogTextInput, setDialogInputText] = useState("")
    const [dialogTextInput2, setDialogInputText2] = useState("")
    const [currentArray, setCurrentArray] = useState([])
    const [dialogTitle, setDialogTitle] = useState(false)
    const [dialogDesc, setDialogDesc] = useState(false)
    const [dialogValue, setDialogValue] = useState(false)

    const handleOpenInputDialog = (title, text, inputOption, array) => {
        setDialogDesc(text)
        setDialogTitle(title)
        setDialogValue(inputOption)
        setCurrentArray(array)
        setDialogInput(true)
    };
    const handleClose = () => { setDialogInput(false); };
    const handleAddClick = () => {
        setDialogInput(false)
        setIsLoading(true)
        var object;
        if (dialogValue === "notes" || dialogValue === "otherResidents") {
            object = {
                primary: dialogTextInput
            }
        } else {
            object = {
                primary: dialogTextInput,
                secondary: dialogTextInput2
            }
        }
        const promise = addHouseData(dialogValue, object, communityData.LoggedObject.placeType, communityData.LoggedObject.placeID, residentInfo.houseInfo.houseID, currentArray)
        promise.then((e) => {
            if (dialogValue === "notes") {
                setNotesArray(e)
            } else if (dialogValue === "otherResidents") {
                setOldResidentsArray(e)
            } else {
                setOldPhonesArray(e)
            }
            setIsLoading(false)
            setSuccessAlert(true)
            return;
        }).catch((error) => {
            setErrorAlert(true)
            console.log(error)
            setIsLoading(false)
        })
    }

    const handleDeleteInfoClick = (keyToDelete, currentArray, type) => {
        const promise = deleteHouseData(type, keyToDelete, communityData.LoggedObject.placeType, communityData.LoggedObject.placeID, residentInfo.houseInfo.houseID, currentArray)
        promise.then((e) => {
            if (type === "notes") {
                setNotesArray(e)
            } else if (type === "otherResidents") {
                setOldResidentsArray(e)
            } else {
                setOldPhonesArray(e)
            }
            setIsLoading(false)
            setSuccessAlert(true)
            return;
        }).catch((error) => {
            setErrorAlert(true)
            console.log(error)
            setIsLoading(false)
        })
    }

    const handleEmailSending = async () => {
        setIsLoading(true)
        var url = 'https://us-west2-guestpass-6b5e3.cloudfunctions.net/resendEmailHouseIdToResident'
        var data = await createEmailSendingHouseIdObject(residentInfo.houseInfo.houseID, residentEmail, residentName, communityData.LoggedObject.placeID)
        const promise = httpRequestEditUserInfo(url, data)
        promise.then(() => {
            setSuccessAlert(true)
            setIsLoading(false)
            return;
        }).catch((error) => {
            setErrorAlert(true)
            console.log("Error")
            console.log(error)
            setIsLoading(false)
        })
    }

    const handleNameChange = async (newValue, lastValue) => {
        setIsLoading(true)
        var url = 'https://us-west2-guestpass-6b5e3.cloudfunctions.net/editUserName'
        var data = await createEditInputRequestObject(newValue, lastValue, communityData.LoggedObject.placeID, residentInfo.houseInfo.houseID, props.residentID)
        const promise = httpRequestEditUserInfo(url, data)
        return promise.then((response) => {
            setIsLoading(false)
            if (response.code === 200) {
                props.residentListMap[props.residentID]['fullname'] = newValue
                props.updateResidentList(props.residentListMap)
                setSuccessAlert(true)
                return newValue;
            } else {
                setErrorMessageState(response.message)
                setErrorAlert(true)
                return lastValue;
            }
        }).catch((error) => {
            setErrorAlert(true)
            console.log("Error")
            console.log(error)
            setIsLoading(false)
            return lastValue;
        })
    }

    const handleEmailChange = async (newValue, lastValue) => {
        setIsLoading(true)
        var url = 'https://us-west2-guestpass-6b5e3.cloudfunctions.net/editUserEmail'
        var data = await createEditInputRequestObject(newValue, lastValue, communityData.LoggedObject.placeID, residentInfo.houseInfo.houseID, props.residentID)
        const promise = httpRequestEditUserInfo(url, data)
        return promise.then((response) => {
            setIsLoading(false)
            if (response.code === 200) {
                props.residentListMap[props.residentID]['email'] = newValue
                props.updateResidentList(props.residentListMap)
                setResidentEmail(newValue)
                setSuccessAlert(true)
                return newValue;
            } else {
                setErrorMessageState(response.message)
                setErrorAlert(true)
                return lastValue;
            }
        }).catch((error) => {
            setIsLoading(false)
            setErrorAlert(true)
            console.log("Error")
            console.log(error)
            return lastValue;
        })
    }

    const handleHouseOwnerChange = async (newValue, lastValue) => {
        setIsLoading(true)
        return firestore.collection(communityData.LoggedObject.placeType).doc(communityData.LoggedObject.placeID)
            .collection("houses").doc(residentInfo.houseInfo.houseID).update({ fullname: newValue }).then(() => {
                setIsLoading(false)
                return newValue;
            }).catch((error) => {
                console.log(error)
                setIsLoading(false)
                return lastValue;
            })
    }
    const handlePhoneChange = async (newValue, lastValue) => {
        setIsLoading(true)
        var url = 'https://us-west2-guestpass-6b5e3.cloudfunctions.net/editUserPhone'
        var data = await createEditInputRequestObject(newValue, lastValue, communityData.LoggedObject.placeID, residentInfo.houseInfo.houseID, props.residentID)
        const promise = httpRequestEditUserInfo(url, data)
        return promise.then((response) => {
            setIsLoading(false)
            if (response.code === 200) {
                props.residentListMap[props.residentID]['phone'] = newValue
                props.updateResidentList(props.residentListMap)
                setSuccessAlert(true)
                return newValue;
            } else {
                setErrorMessageState(response.message)
                setErrorAlert(true)
                return lastValue;
            }
        }).catch((error) => {
            setErrorAlert(true)
            console.log("Error")
            console.log(error)
            setIsLoading(false)
            return lastValue;
        })
    }

    // Blocked Guest Modal
    const [modalAddBlockedGuest, setModalAddBlockedGuest] = useState(false)
    const [modalAddGuestBlockedInput, setModalAddGuestBlockedInput] = useState("")
    const [blockedGuests, setBlockedGuests] = useState(props.residentObject.houseInfo.blockedguests)
    const handleCloseAddGuestModal = () => { setModalAddBlockedGuest(false); };
    const handleOpenAddGuestModal = () => {
        setModalAddBlockedGuest(true);
        setModalAddGuestBlockedInput("")
    };
    const handleAddBlockedGuest = () => {
        if (modalAddGuestBlockedInput === "" || modalAddGuestBlockedInput === null || modalAddGuestBlockedInput === undefined) {
            setErrorMessageState("Please fill a name")
            setErrorAlert(true)
            return;
        } else {
            const promiseAddBlockedGuest = addBlockedGuestFunction(modalAddGuestBlockedInput, communityData.LoggedObject.placeType, communityData.LoggedObject.placeID, residentInfo.houseInfo.houseID, blockedGuests)
            promiseAddBlockedGuest.then((response) => {
                if (response.code === 200) {
                    setBlockedGuests(response.array)
                    setSuccessAlert(true)
                    return;
                } else {
                    setErrorMessageState(response.message)
                    setErrorAlert(true)
                }
            }).catch((error) => {
                console.log(error)
                setErrorAlert(true)
                return;
            })
        }
    }
    //
    const addPlate = (plateDetails) => {
        var promiseDeletePlatePlate = pushNewPlateToDatabase(plateDetails, communityData.LoggedObject, residentInfo.houseInfo.houseID)
        promiseDeletePlatePlate.then((response) => {
            if (response.code === 200) {
                setPlates(prevPlates => prevPlates.concat(plateDetails))
                setSuccessAlert(true)
                return;
            } else {
                setErrorMessageState(response.message)
                setErrorAlert(true)
            }
        }).catch((error) => {
            console.log(error)
            setErrorAlert(true)
            return;
        })
    }

    const changeGuestsVisiblity = () => {
        setVisibleGuests(prevVisibility => !prevVisibility)
    }

    const deletePlate = (plateNumber) => {
        setIsLoading(true)
        var promiseAddPlate = deleteCarFromDatabase(plateNumber, communityData.LoggedObject, residentInfo.houseInfo.houseID)
        promiseAddPlate.then((response) => {
            if (response.code === 200) {
                setPlates(prevPlates => _.remove(prevPlates, item => item.plate !== plateNumber.plate))
                setSuccessAlert(true)
                setIsLoading(false)
                return;
            } else {
                setErrorAlert(true)
            }
        }).catch((error) => {
            setIsLoading(false)
            setErrorAlert(true)
            console.log(error)
            return;
        })
    }

    return (
        <>
            <PageTitle title={"Resident Details"} />
            <Paper className={classes.paperContainer}>
                <Grid container spacing={6} alignItems="center" justify="center">
                    <Grid item container xs={12} spacing={3} className={classes.textFieldRow}>
                        <EditableDetails
                            residentInfo={residentInfo}
                            classes={classes}
                            toolTip={"Change resident name"}
                            id="name"
                            label="Name:"
                            name={residentName}
                            handleEditChange={handleNameChange}>
                        </EditableDetails>
                        <EditableDetails
                            residentInfo={residentInfo}
                            classes={classes}
                            toolTip={"Change resident email"}
                            id="email"
                            label="Email:"
                            name={residentEmail}
                            handleEditChange={handleEmailChange}>
                        </EditableDetails>
                        <EditableDetails
                            residentInfo={residentInfo}
                            classes={classes}
                            toolTip={"Change resident phone number"}
                            id="phone"
                            label="Phone:"
                            name={residentPhone}
                            handleEditChange={handlePhoneChange}>
                        </EditableDetails>
                        <Grid item container xs={4} className={classes.resident}>
                            <Grid item xs={10}>
                                <TextField
                                    className={classes.textField}
                                    id="address"
                                    label="Address:"
                                    defaultValue={props.residentObject.houseInfo.address}
                                    variant="outlined"
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <EditableDetails
                            residentInfo={residentInfo}
                            classes={classes}
                            toolTip={"Change house owner name"}
                            id="ownername"
                            label="Owner Name:"
                            name={residentInfo.houseInfo.ownerName}
                            handleEditChange={handleHouseOwnerChange}>
                        </EditableDetails>
                        <Grid item container xs={4} alignItems="center" className={classes.resident}>
                            <Grid item xs={10}>
                                <TextField
                                    className={classes.textField}
                                    id="houseID"
                                    label="House ID:"
                                    defaultValue={residentInfo.houseInfo.houseID}
                                    variant="outlined"
                                    disabled
                                />
                            </Grid>
                            <Grid item container xs={2} justify="center" alignContent="center" alignItems="center">
                                <Tooltip title="Send house ID to resident's email" aria-label="msg">
                                    <Fab
                                        variant="extended"
                                        color="primary"
                                        size="small"
                                        onClick={() => handleEmailSending()}
                                        className={classes.bottomButtons}
                                    >
                                        <MessageIcon />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={3} className={classes.textFieldRow}>
                        <Grid container item direction='row' xs={7} spacing={2}>
                            <InfoOldHouseList editable={true} handleDeleteInfoClick={handleDeleteInfoClick} showDialogfunction={handleOpenInputDialog} classes={classes} type="otherResidents" title={"Other Residents"} secondary={false} array={oldResidentsArray} gridSize={6} />
                            <InfoOldHouseList editable={true} handleDeleteInfoClick={handleDeleteInfoClick} showDialogfunction={handleOpenInputDialog} classes={classes} type="additionalPhones" title={"Additional phones"} secondary={true} array={oldPhonesArray} gridSize={6} />
                        </Grid>
                        <Grid item xs={5} className={classes.gridContainerBox}>
                            <InfoOldHouseList editable={true} handleDeleteInfoClick={handleDeleteInfoClick} showDialogfunction={handleOpenInputDialog} classes={classes} type="notes" title={"Notes"} secondary={false} array={notesArray} gridSize={12} />
                        </Grid>
                    </Grid>
                    <TitleDivider titleSize={"h3"} title="Plates"></TitleDivider>
                    <PlatesTable
                        classes={classes}
                        plates={plates}
                        addPlate={addPlate}
                        deletePlate={deletePlate}>
                    </PlatesTable>
                    {visibleGuests ?
                        <Grid item container xs={12} spacing={6}>
                            <TitleDivider titleSize={"h3"} title="Guests"></TitleDivider>
                            <Grid className={classes.containersBackground} item container xs={12} spacing={1}>
                                <GuestsList
                                    communityInfo={communityData.LoggedObject}
                                    houseID={residentInfo.houseInfo.houseID}
                                    residentID={props.residentID}
                                    classes={classes}>
                                </GuestsList>
                            </Grid>
                            <Grid container justify="center" alignContent="center" alignItems="center" direction="row">
                                <TitleDivider titleSize={"h3"} title="Blocked Guests"></TitleDivider>
                                <IconButton className={classes.addDialog} onClick={() => handleOpenAddGuestModal()}>
                                    <AddCircle />
                                </IconButton>
                            </Grid>
                            <Grid className={classes.containersBackground} item container xs={12} spacing={1}>
                                <BlockedGuestsList
                                    communityInfo={communityData.LoggedObject}
                                    houseID={residentInfo.houseInfo.houseID}
                                    residentID={props.residentID}
                                    data={blockedGuests}
                                    classes={classes}>
                                </BlockedGuestsList>
                            </Grid>
                        </Grid>
                        : null}
                    {/* Blocked Guests Table here Gal code */}
                    <PageBottomButtons
                        classes={classes}
                        setSuccessAlert={setSuccessAlert}
                        setErrorAlert={setErrorAlert}
                        setIsLoading={setIsLoading}
                        communityInfo={communityData.LoggedObject}
                        buttonText="Show Guests"
                        visible={visibleGuests}
                        userPushEnabled={isMessageEnabled}
                        changeGuestsVisiblity={changeGuestsVisiblity}>
                    </PageBottomButtons>
                </Grid>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress style={{ position: 'absolute' }} color="primary" />
                </Backdrop>
            </Paper>
            <Dialog fullWidth={true} maxWidth={'sm'} open={dialogInputState} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogDesc}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        label="Fill here"
                        fullWidth
                        onChange={(event) => setDialogInputText(event.target.value)}
                    />
                    {(dialogValue === "additionalPhones") ?
                        <TextField
                            margin="dense"
                            id="name2"
                            label="Description"
                            fullWidth
                            onChange={(event) => setDialogInputText2(event.target.value)}
                        /> : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddClick} color="primary">
                        Add
                    </Button>
                </DialogActions>
                {/* <ToastComponent showToast={openAlert} onClose={closeAlertSuccess} onOpen={showAlertSuccess} /> */}
            </Dialog>
            <Dialog fullWidth={true} maxWidth={'sm'} open={modalAddBlockedGuest} onClose={handleCloseAddGuestModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Blocked Guest</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please type guest name
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        label="Fill here"
                        fullWidth
                        onChange={(event) => setModalAddGuestBlockedInput(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddGuestModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddBlockedGuest} color="primary">
                        Add
                    </Button>
                </DialogActions>
                {/* <ToastComponent showToast={openAlert} onClose={closeAlertSuccess} onOpen={showAlertSuccess} /> */}
            </Dialog>
            {successAlert ?
                <Snackbar open={successAlert} autoHideDuration={2000} onClose={handleAlertClose}>
                    <Alert onClose={handleAlertClose} severity="success">Success!</Alert>
                </Snackbar>
                : null}
            {errorAlert ?
                <Snackbar open={errorAlert} autoHideDuration={2000} onClose={handleAlertClose}>
                    <Alert onClose={handleAlertClose} severity="error">{errorMessageState}</Alert>
                </Snackbar>
                : null}
        </>
    );
}

export default function PreRenderResidentScreen(props) {
    const [residentFetch, setResidentFetch] = useState()
    var residentID = props.match.params.residentid;

    useEffect(() => {
        if (props.residentListMapProp[residentID] !== undefined && residentID !== undefined && props.communityIdProp !== undefined) {
            getResidentObject(props.residentListMapProp, residentID, props.communityIdProp)
                .then((values) => {
                    setResidentFetch(values)
                    console.log(values)
                    return;
                }).catch((error) => {
                    console.log(error)
                    return;
                })
        } else {
            return;
        }
    }, [residentID, props.residentListMapProp, props.communityIdProp])
    if (props.residentListMapProp[residentID] === undefined) {
        return <Redirect to={"/app/dashboard"} />
    }
    if (!residentFetch) {
        return (
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100%' }}>
                <CircularProgress />
            </div>
        )
    } else {
        return (
            <div>
                <ResidentScreen residentListMap={props.residentListMapProp} updateResidentList={props.updateResidentList} props={props} residentObject={residentFetch} residentID={residentID} />
            </div>
        )
    }
}