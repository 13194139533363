import React from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { getFirestoreGuests, getRealtimeGuests } from "../Functions";
import _ from 'lodash'

const columns = [
  {
    name: "guestName",
    label: "Guest Name",
  },
  {
    name: "job",
    label: "Job",
  },
  {
    name: "type",
    label: "Type",
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: "lefttime",
    label: "Left Time",
  },
  {
    name: "timeLimit",
    label: "Time Limit",
    options: {
      display: true,
      filter: false,
    }
  },
  {
    name: "guestid",
    label: "ID",
    options: {
      display: false,
      filter: false,
      sort: true
    }
  },

]

export class GuestsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      guestList: []
    };
  }
  componentDidMount() {
    const promiseRealtime = getRealtimeGuests(this.props.communityInfo, this.props.houseID, this.props.residentID)
    const promiseFirestore = getFirestoreGuests(this.props.communityInfo,this.props.houseID, this.props.residentID)
    Promise.all([promiseFirestore, promiseRealtime])
    .then((promiseArray) => {
      var array = _.concat(promiseArray[0], promiseArray[1])
      this.setState({guestList: array})
      return;
    }).catch((error) => {
      console.log(error)
      return;
    })
  }
  render() {
    return (
      <Grid item xs={12}>
        <MUIDataTable
          data={this.state.guestList}
          columns={columns}
          options={{
            viewColumns: false,
            selectableRows: "none",
            rowsPerPage: 15,
          }}
        />
      </Grid>
    );
  }
}