import moment from 'moment-timezone';
import { db, firestore, functions, Analytics } from '../../context/Firebase';
import _ from 'lodash';
// PlaceValues:
    // placeID: props.communityIdProp.placeID,
    // placeType: props.communityIdProp.placeType,
    // stateID: props.communityIdProp.stateID,
    // timezone: props.communityIdProp.timezone
// Item = {
    //     "guestid": "xGzNqYSJutR7w6yFIpiOgxfRl27o",
    //     "guestname": "Check Me In",
    //     "houseid": "awJxXUOIL7lSUu2N4nTd",
    //     "lefttime": 0,
    //     "plate": "",
    //     "residentAddress": "1234 Test",
    //     "residentName": "Test Biran",
    //     "residentPhone": "0509016001",
    //     "residentid": "awJxXUOIL7lSUu2N4nTd",
    //     "type": "guest",
    //   }
export function _verifyentrance(item, placeValues) {
    if (item.guestname != null & item.guestid != null && item.residentid != null) {
        const promiseInsertToRealtime = _pushToEnterTodayRealtime(item, "", placeValues)
        const promiseSendNotification = sendPushNotification(item.houseid, item.residentid, item.guestname, placeValues)
        const promiseLogEvent = _registerLogCheckInEvent(placeValues)
        return Promise.all([promiseInsertToRealtime, promiseSendNotification, promiseLogEvent])
            .then(() => {
                return true;
            })
            .catch((error) => {
                console.log(error)
                console.log("Error verify entrance")
                console.log("...")
                alert("Something Went wrong")
                return;
            })
    } else {
        console.log("Error verify entrance")
        console.log("...")
        console.log("Guest name or Guest ID or Resident ID is null!")
        console.log("...")
    }
}


export function _verifycarenter(item, plate, placeValues) {
    if (plate.includes("@")) {
        alert("Cant contain @ in plate")
        return;
    } else {
        if (item.guestname != null & item.guestid != null && item.residentid != null) {
            const promiseInsertToRealtime = _pushToEnterTodayRealtime(item, plate, placeValues)
            const promiseSendNotification = sendPushNotification(item.houseid, item.residentid, item.guestname, placeValues)
            const promiseLogEvent = _registerLogCheckInEvent(placeValues)
            return Promise.all([promiseInsertToRealtime, promiseSendNotification, promiseLogEvent])
                .then((printValue) => {
                    return true;
                })
                .catch((error) => {
                    console.log(error)
                    console.log("Error Car Enter")
                    console.log("...")
                    alert("Something Went wrong")
                })
        } else {
            console.log("Error verify car entrance")
            console.log("...")
            console.log("Guest name or Guest ID or Resident ID is null!")
            console.log("...")
        }
    }
}

async function _registerLogCheckInEvent(placeValues) {
    // Screen ['QRCode', 'AddGuestCheckIn', 'ResidentGuests', 'GuestList']
    return Analytics.logEvent('Guest_Check_In', {
        name: 'Guest_Check_In',
        placeID: placeValues.placeID,
        platform: "Admin-Dashboard",
        checkInMethod: "Website"
    })
}

function _pushToEnterTodayRealtime(item, plate, placeValues) {
    var time = moment().tz('America/Los_Angeles').unix();
    const returnedPromise = new Promise((resolve, reject) => {
        db.ref('/' + placeValues.placeType + '/' + placeValues.stateID + '/' + placeValues.placeID + '/databaseEnterToday/' + item.houseid + '/' + item.residentid)
            .child(item.guestid + '@' + time).set({
                "guestvalue": item.guestname + '@' + plate,
                "type": item.type
            })
            .catch((error) => {
                console.log(error)
                console.log("Cant Insert to Database")
                console.log("...")
                console.log("Error Message:")
                console.log("...")
                console.log(error.message)
                console.log(item)
                console.log("...")
                reject(error.message)
            })
        resolve()
    })
    return returnedPromise;
};

function sendPushNotification(houseid, residentid, guestname, placeValues) {
    const returnPromise = new Promise((resolve, reject) => {
        var promise = db.ref('/' + placeValues.placeType + '/' + placeValues.stateID + '/' + placeValues.placeID + '/' + houseid + '/' + residentid + '/expotoken/').once("value")
            .then((values) => {
                if (values.val() !== null || values.val() !== undefined) {
                    return values.val();
                } else {
                    return 0;
                }
            }).catch((error) => {
                console.log("Error get Expo Token")
                console.log("...")
                console.log("Error message: ")
                console.log("...")
                console.log(error.message)
                console.log("...")
                return 0;
            })
        promise.then((expoToken) => {
            if (expoToken === 0 || expoToken === null || expoToken === undefined) {
                resolve()
                return;
            } else {
                console.log("Sending")
                const body = "" + guestname + ' has entered the gate!'
                return functions.httpsCallable('sendExpoNotificationWebsite')({ body: body, expoToken: expoToken, validateCode: "dlknmalk3dsfg", placeID: placeValues.placeID })
                    .then((e) => {
                        resolve()
                        return;
                    }).catch((error) => {
                        console.log("Error inside")
                        console.log(error)
                        resolve()
                        return;
                    })
            }
        }).catch((error) => {
            console.log("Error outside")
            console.log(error)
            resolve()
            return;
        })
    })
    return returnPromise;
}


export function createGuestObject(guestObject, residentObject) {
    return {
        guestid: guestObject.guestID,
        guestname: guestObject.guestName,
        houseid: residentObject.houseid,
        residentid: guestObject.residentid,
        lefttime: guestObject.lefttime,
        plate: guestObject.plate,
        residentAddress: residentObject.address,
        residentName: guestObject.residentName,
        residentPhone: guestObject.residentPhone,
        type: guestObject.type
    }
}

export function getHouseInfo(communityInfo, houseOwnerObject, residentList) {
    const promiseFirestore = getFirestoreGuests(communityInfo, houseOwnerObject.houseid, residentList)
    const promiseRealtime = getRealtimeGuests(communityInfo, houseOwnerObject.houseid, residentList)
    const promiseHouseDocument = getHouseDocument(houseOwnerObject.houseid, communityInfo)
    return Promise.all([promiseFirestore, promiseRealtime, promiseHouseDocument]).then((values) => {
        return {
            guestList: _.concat(values[0], values[1]),
            moreInfo: values[2]
        }
    }).catch((error) => {
        console.log(error)
        return []
    })
}

function getFirestoreGuests(communityInfo, houseID, residentList) {
    return firestore.collection(communityInfo.placeType).doc(communityInfo.placeID).collection("permanentGuestsByHouseID").doc(houseID).get()
        .then((e) => {
            const snapshotDocument = e.data();
            var returnArray = [];
            if (snapshotDocument !== null && snapshotDocument !== undefined) {
                for (const residentID in snapshotDocument)
                    for (const guestID in snapshotDocument[residentID]) {
                        var job = ""
                        var plate = ""
                        if (snapshotDocument[residentID][guestID]['job'] !== undefined && snapshotDocument[residentID][guestID]['job'] !== "") job = snapshotDocument[residentID][guestID]['job'];
                        if (snapshotDocument[residentID][guestID]['plate'] !== undefined && snapshotDocument[residentID][guestID]['plate'] !== "") plate = snapshotDocument[residentID][guestID]['plate']
                        returnArray.push({
                            guestID: guestID,
                            guestName: snapshotDocument[residentID][guestID]['guestname'],
                            type: capitalizeFirstLetter(snapshotDocument[residentID][guestID]['type']),
                            residentid: residentID,
                            residentName: residentList[residentID]['fullname'],
                            residentPhone: residentList[residentID]['phone'],
                            job: job,
                            plate: plate,
                            lefttime: 0
                        })
                    }
                return returnArray;
            } else {
                return returnArray;
            }
        }).catch((error) => {
            console.log("Error Firestore database")
            console.log("Error code: " + error.code)
            console.log("Message : " + error.message)
            return [];
        })
}

function getRealtimeGuests(communityInfo, houseID, residentList) {
    const now = moment.tz('America/Los_Angeles').unix()
    return db.ref('/' + communityInfo.placeType + '/' + communityInfo.stateID + '/' + communityInfo.placeID + '/databaseActiveGuests/' + houseID).once("value")
        .then((e) => {
            var returnArray = [];
            if (e.val() !== undefined && e.val() !== null) {
                for (const residentID in e.val()) {
                    for (const guestID in e.val()[residentID]) {
                        if (now > e.val()[residentID][guestID]['startdate']) {
                            var job = ""
                            var plate = ""
                            if (e.val()[residentID][guestID]['plate'] !== undefined && e.val()[residentID][guestID]['plate'] !== "") plate = (e.val()[residentID][guestID]['plate'])
                            if (e.val()[residentID][guestID]['job'] !== undefined && e.val()[residentID][guestID]['job'] !== "") job = e.val()[residentID][guestID]['job'];
                            returnArray.push({
                                guestID: guestID,
                                guestName: e.val()[residentID][guestID]['guestname'],
                                type: capitalizeFirstLetter(e.val()[residentID][guestID]['type']),
                                residentid: residentID,
                                residentName: residentList[residentID]['fullname'],
                                residentPhone: residentList[residentID]['phone'],
                                job: job,
                                plate: plate,
                                lefttime: e.val()[residentID][guestID]['enddate'] * 1000
                            })
                        }

                    }
                }
                return returnArray;
            } else {
                return returnArray;
            }
        }).catch((error) => {
            console.log("Error realtime database")
            console.log("Error code: " + error.code)
            console.log("Message : " + error.message)
            return [];
        })
}

function getHouseDocument(houseID, communityInfo) {
    return firestore.collection(communityInfo.placeType).doc(communityInfo.placeID).collection("houses").doc(houseID).get()
        .then(async (e) => {
            if (e.data()) {
                const oldPhones = arrangeOldDataInArray(e.data().additionalPhones)
                const notes = arrangeOldDataInArray(e.data().notes)
                const blockedguests = arrangeBlockedGuests(e.data().blockedguests)
                return {
                    oldPhones: oldPhones,
                    notes: notes,
                    blockedGuests: blockedguests
                }
            } else {
                return {
                    oldPhones: [],
                    notes: [],
                    blockedGuests: []
                }
            }
        }).catch((error) => {
            console.log(error)
            return {
                oldPhones: [],
                notes: [],
                blockedGuests: []
            }
        })
}

function arrangeOldDataInArray(map) {
    if (map !== undefined && map !== null) {
        if (Object.keys(map).length > 0) {
            var returnArray = [];
            for (const key in map) {
                if (map[key]['secondary'] === null || map[key]['secondary'] === undefined) {
                    returnArray.push({
                        primary: map[key]['primary'],
                        id: key
                    })
                } else {
                    returnArray.push({
                        primary: map[key]['primary'],
                        secondary: map[key]['secondary'],
                        id: key
                    })
                }
            }
            return returnArray;
        } else {
            return []
        }
    } else {
        return []
    }
}

function arrangeBlockedGuests(map) {
    if (map !== undefined && map !== null) {
        if (Object.keys(map).length > 0) {
            var returnArray = [];
            for (const key in map) {
                returnArray.push({
                    primary: map[key]['guestname'],
                    timeAdded: map[key]['createdAt'],
                    id: key
                })
            }
            return returnArray;
        } else {
            return []
        }
    } else {
        return []
    }
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}