import { Grid } from "@material-ui/core";
import _ from 'lodash';
import moment from "moment-timezone";
import MUIDataTable from "mui-datatables";
import React, { useCallback, useEffect, useState } from "react";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import { enterTodayColumns } from '../../config/columns';
import { db } from '../../context/Firebase';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';





export default function TodayEntrances(props) {
  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          fontSize: 11,
        }
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: 12,
        }
      },
    }
  })


  const [EntraceHistory, setEntraceHistory] = useState([])

  const [communityData] = useState(props.communityIdProp)


  const handleGuest = useCallback((guestIDandTimestamp, nameAndPlate, guestType, residentName, Address, Phone, timezone) => {
    const stringfyNameAndPlate = String(nameAndPlate)
    const stringfyGuestIDAndTimestamp = String(guestIDandTimestamp)
    var plate = stringfyNameAndPlate.substr(stringfyNameAndPlate.indexOf("@") + 1, stringfyNameAndPlate.length);
    var guestname = stringfyNameAndPlate.substr(0, stringfyNameAndPlate.indexOf("@"));
    var timestamp = stringfyGuestIDAndTimestamp.substr(stringfyGuestIDAndTimestamp.indexOf("@") + 1, stringfyGuestIDAndTimestamp.length)
    var timestampFormat = moment.tz(timestamp * 1000, timezone).format("HH:mm")
    var guestid = stringfyGuestIDAndTimestamp.substr(0, stringfyGuestIDAndTimestamp.indexOf("@") - 1)
    return {
      "guestName": guestname,
      "residentName": residentName,
      "residentAddress": Address,
      "residentDisplayedPhone": makePhoneOk(Phone),
      "residentPhone": Phone,
      "formatTimestamp": timestampFormat,
      "plate": plate,
      "guestType": guestType,
      "timestamp": timestamp,
      "guestid": guestid
    }
  }, [])

  useEffect(() => {
    var returnArray = [];
    db.ref('/' + communityData.placeType + '/' + communityData.stateID + '/' + communityData.placeID + '/databaseEnterToday/').once("value")
      .then((data) => {
        if (data.val() !== null && props.residentListMapProp !== null) {
          var snapshot = data.val();
          for (var houseID in snapshot) {
            for (var residentID in snapshot[houseID]) {
              if (props.residentListMapProp[residentID] !== undefined) {
                let residentName;
                let residentAddress;
                let residentPhone;
                if (residentID === "CommunityHouse") {
                  residentName = "Community House"
                  residentAddress = "Community address"
                } else {
                  residentName = props.residentListMapProp[residentID].fullname;
                  residentAddress = props.residentListMapProp[residentID].address;
                  residentPhone = props.residentListMapProp[residentID].phone;
                }
                for (var guestID in snapshot[houseID][residentID]) {
                  const currentguest = handleGuest(guestID, snapshot[houseID][residentID][guestID].guestvalue, snapshot[houseID][residentID][guestID].type, residentName, residentAddress, residentPhone, communityData.timezone)
                  returnArray.push(currentguest)
                }
              }
            }
          }
          const sortedArray = _.orderBy(returnArray, ['timestamp'], ['desc'])
          setEntraceHistory(sortedArray)
          return;
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [communityData, props.residentListMapProp, handleGuest])



  return (
    <>
      <PageTitle title={"Today Entrances"} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title=""
              data={EntraceHistory}
              columns={enterTodayColumns}
              options={{
                filter: false,
                viewColumns: false,
                selectableRows: "none",
                rowsPerPage: 15,
              }}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </>
  );
}
function makePhoneOk(phone) {
  if (phone === undefined || phone === null || String(phone).length < 10) return phone;
  return "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + "-" + phone.substring(6, 10)
}