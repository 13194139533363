import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  card: {
    backgroundColor: '#F0F8FF',
    height: 500,
    flex: 1,
    minHeight: 300,
  },
  cardleft: {
    backgroundColor: '#F0F8FF',
    height: 500,
    minHeight: 300,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  margin: {
    margin: theme.spacing(7),
    width: '25vw',
    display: 'flex',
    backgroundColor: 'blue',
    textTransform: "none",
  },
  formControl: {
    backgroundColor: '#F0F8FF'
  },
  formInput: {
    margin: 4
  },
  settingsButton: {
    fontWeight: 'bold',
    fontSize: 15,
  },
  fullHeightBody: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  button: {
    marginTop: 5,
  },
  feedbackContainer: {
    justifyContent:'center',
    alignItems:'center'

  },
  buttonContainer: {
    justifyContent:'center',
    alignItems:'center'
  },
  container: {
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    // alignItems:'center',
    backgroundColor:'red'
    // backgroundColor:'red'
  },
  actionIcons: {
    color: "#0092b3",
    opacity: "0.5"
  }
}))
