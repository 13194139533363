import React from 'react';
import BlackLogo from '../../../assets/blackLogoForPass.png'

export class PrintablePass extends React.PureComponent {
    render() {
        if (this.props.guestObject) {
            return (
                <table style={{ height: '100%', width: '100%', alignItems: 'center' }}>
                    <thead style={{ textAlign: 'center' }}>
                        <tr>
                            <td>
                                <div>
                                    <img src={BlackLogo} width={96} height={53} alt="Liminal" />
                                    <p style={{ fontWeight: 'bold', fontSize: 23, color: 'black', marginTop: 1, fontFamily: 'sans-serif' }}>{this.props.guestObject.placeID} Community </p>
                                    <div style={{ height: 1, width: '100%', backgroundColor: 'darkgrey' }} />
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <tr style={{ textAlign: 'center', alignItems: "center", justifyContent: 'center' }}>
                            <td>
                                <div style={{ textAlign: 'center', alignItems: "center", justifyContent: 'center' }}>
                                    <p style={{ fontWeight: '400', fontSize: 13 }}>Guest Name:</p>
                                    <p style={{ fontWeight: 'bold', fontSize: 22 }}>{this.props.guestObject.guestName}</p>
                                </div>
                                <div style={{ textAlign: 'center', alignItems: "center", justifyContent: 'center' }}>
                                    <div>
                                        <p style={{ fontWeight: '400', fontSize: 13 }}>Resident Name:</p>
                                        <p style={{ fontWeight: 'bold', fontSize: 16 }}>{this.props.guestObject.residentName}</p>
                                    </div>
                                </div>
                                <div style={{ textAlign: 'center', alignItems: "center", justifyContent: 'center' }}>
                                    <div>
                                        <p style={{ fontWeight: '400', fontSize: 13 }}>Resident Address:</p>
                                        <p style={{ fontWeight: 'bold', fontSize: 16 }}>{this.props.guestObject.address}</p>
                                    </div>
                                </div>
                                <div style={{ textAlign: 'center', alignItems: "center", justifyContent: 'center' }}>
                                    <p style={{ fontWeight: '400', fontSize: 13 }}>Valid Until:</p>
                                    <p style={{ fontWeight: 'bold', fontSize: 16 }}>{this.props.guestObject.expiredDate}</p>
                                </div>
                                <div style={{ textAlign: 'center', alignItems: "center", justifyContent: 'center' }}>
                                    <p style={{ fontWeight: '400', fontSize: 13 }}>Plate :</p>
                                    <p style={{ fontWeight: 'bold', fontSize: 16 }}>{this.props.guestObject.plate}</p>
                                </div>
                                {/* <div style={{ textAlign: 'center', alignItems: "center", justifyContent: 'center' }}>
                                    <img src={this.props.guestObject.url} alt="" width={84} height={84} />
                                </div> */}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot style={{ textAlign: 'center', alignItems: "center", justifyContent: 'center' }}>
                        <tr>
                            <td>
                                <div>
                                    <div style={{ height: 1, width: '100%', backgroundColor: 'darkgrey' }} />
                                    <div>
                                        <p style={{ fontWeight: '500', fontSize: 18, color: 'black', marginTop: 1, fontFamily: 'sans-serif' }}>{this.props.guestObject.currentTime} </p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            );
        } else {
            return;
        }
    }
}