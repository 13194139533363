import moment from 'moment-timezone';
import { firestore, db, firestoreFieldvalue, Analytics } from '../../../context/Firebase'



export async function handleRequestButton(userInfo, guestType, guestTimeLimit, mainName, secondName, startDate, endDate, plate) {
    // Error 301 - not letter or one of string is length of 0
    // Error 302 - Invalid Dates
    if (!validNames(mainName)) return Promise.reject({ code: 301, message: "First name or Vendor name can contain only letters and '&' or name to shorts" })
    if (guestType === "guest" && !validNames(secondName)) return Promise.reject({ code: 302, message: "Last name can contain only letters and '&' or name to shorts" })
    if (guestTimeLimit === "temp" && !validDates(startDate, endDate)) return Promise.reject({ code: 302, message: "Invalid dates" })
    if (userInfo.placeType == null || userInfo.stateID == null || userInfo.houseID == null || userInfo.residentID == null) return Promise.reject({ code: 303, message: "Some error occurd" })
    var guestName;
    var job = null;
    if (guestType === "guest") {
        guestName = mainName.trim() + " " + secondName.trim()
    } else {
        guestName = mainName.trim()
        if (secondName != null && secondName.length > 1) job = secondName;
    }
    if (guestTimeLimit === "temp" && guestType === "guest") return addGuestToTemp(userInfo, guestName, startDate, endDate, checkIfToday(startDate), plate)
    else if (guestTimeLimit === "perm" && guestType === "guest") return addGuestToPerm(userInfo, guestName, plate)
    else if (guestTimeLimit === "temp" && guestType === "vendor") return addVendorToTemp(userInfo, guestName, startDate, endDate, checkIfToday(startDate), job, plate)
    else return addVendorToPerm(userInfo, guestName, job, plate)
}
export async function addVendorToPerm(userInfo, guestName, job, plate) {
    const guestID = makeid(28);
    const data = userInfo.residentID + "." + guestID
    var guestObject;
    var guestObjectWithInfo;
    if (job !== "") {
        if (plate !== "") {
            guestObject = {
                guestname: guestName,
                type: "vendor",
                job: job,
                createdAt: firestoreFieldvalue.FieldValue.serverTimestamp(),
                plate: plate
            }
            guestObjectWithInfo = {
                guestname: guestName,
                type: "vendor",
                lastname: String(guestName).split(" ").pop().toLowerCase(),
                queryguestname: String(guestName).toLowerCase(),
                residentid: userInfo.residentID,
                houseid: userInfo.houseID,
                job: job,
                createdAt: firestoreFieldvalue.FieldValue.serverTimestamp(),
                plate: plate
            }
        } else {
            guestObject = {
                guestname: guestName,
                type: "vendor",
                createdAt: firestoreFieldvalue.FieldValue.serverTimestamp(),
                job: job
            }
            guestObjectWithInfo = {
                guestname: guestName,
                type: "vendor",
                lastname: String(guestName).split(" ").pop().toLowerCase(),
                queryguestname: String(guestName).toLowerCase(),
                residentid: userInfo.residentID,
                houseid: userInfo.houseID,
                createdAt: firestoreFieldvalue.FieldValue.serverTimestamp(),
                job: job
            }
        }
    } else {
        if (plate !== "") {
            guestObject = {
                guestname: guestName,
                type: "vendor",
                createdAt: firestoreFieldvalue.FieldValue.serverTimestamp(),
                plate: plate
            }
            guestObjectWithInfo = {
                guestname: guestName,
                lastname: String(guestName).split(" ").pop().toLowerCase(),
                queryguestname: String(guestName).toLowerCase(),
                type: "vendor",
                residentid: userInfo.residentID,
                houseid: userInfo.houseID,
                createdAt: firestoreFieldvalue.FieldValue.serverTimestamp(),
                plate: plate
            }
        } else {
            guestObject = {
                guestname: guestName,
                type: "vendor",
                createdAt: firestoreFieldvalue.FieldValue.serverTimestamp(),
            }
            guestObjectWithInfo = {
                guestname: guestName,
                lastname: String(guestName).split(" ").pop().toLowerCase(),
                queryguestname: String(guestName).toLowerCase(),
                type: "vendor",
                residentid: userInfo.residentID,
                houseid: userInfo.houseID,
                createdAt: firestoreFieldvalue.FieldValue.serverTimestamp(),
            }
        }
    }
    const promiseLogEvent = Analytics.logEvent("Add_Guest", {
        name: "Add_Guest",
        guestType: "Vendor",
        timeLimit: "Permanent",
        placeID: userInfo.placeID,
        app: "Admin-Dashboard"
    })
    const promiseFirestore = firestore.collection(userInfo.placeType).doc(userInfo.placeID).collection("permanentGuestsByHouseID").doc(userInfo.houseID).update({ [data]: guestObject })
        .catch((error) => {
            console.log(error)
        })
    const promisePushDocument = firestore.collection(userInfo.placeType).doc(userInfo.placeID).collection("permanentGuests").doc(guestID).set(guestObjectWithInfo)
        .catch((error) => {
            console.log(error)
        })
    // const ref = '/' + userInfo.placeType + '/' + userInfo.stateID + '/' + userInfo.placeID
    return Promise.all([promiseFirestore, promisePushDocument, promiseLogEvent]).then(() => {
        return ({
            code: 200,
            message: "Successfully added Vendor to permanent passes",
            guestID: guestID,
            guestname: guestName,
            job: job,
            addToList: true,
            leftTime: 0,
            guestType: "vendor",
            timeLimit: "perm"
        })
    }).catch((error) => {
        console.log(error)
        console.log("Error AddVendorToPerfunction")
        Promise.reject(error)
    })
}

export async function addGuestToPerm(userInfo, guestName, plate) {
    const guestID = makeid(28);
    const data = userInfo.residentID + "." + guestID
    let guestObject;
    let guestObjectWithInfo;
    if (plate !== "") {
        guestObject = {
            guestname: guestName,
            type: "guest",
            createdAt: firestoreFieldvalue.FieldValue.serverTimestamp(),
            plate: plate
        }
        guestObjectWithInfo = {
            guestname: guestName,
            type: "guest",
            residentid: userInfo.residentID,
            houseid: userInfo.houseID,
            lastname: String(guestName).split(" ").pop().toLowerCase(),
            queryguestname: String(guestName).toLowerCase(),
            createdAt: firestoreFieldvalue.FieldValue.serverTimestamp(),
            plate: plate
        }
    } else {
        guestObject = {
            guestname: guestName,
            createdAt: firestoreFieldvalue.FieldValue.serverTimestamp(),
            type: "guest"
        }
        guestObjectWithInfo = {
            guestname: guestName,
            type: "guest",
            lastname: String(guestName).split(" ").pop().toLowerCase(),
            queryguestname: String(guestName).toLowerCase(),
            createdAt: firestoreFieldvalue.FieldValue.serverTimestamp(),
            residentid: userInfo.residentID,
            houseid: userInfo.houseID,
        }
    }
    const promiseLogEvent = Analytics.logEvent("Add_Guest", {
        name: "Add_Guest",
        guestType: "Guest",
        placeID: userInfo.placeID,
        timeLimit: "Permanent",
        app: "Admin-Dashboard"
    })
    const promiseFirestore = firestore.collection(userInfo.placeType).doc(userInfo.placeID).collection("permanentGuestsByHouseID").doc(userInfo.houseID).update({ [data]: guestObject })
        .catch((error) => {
            console.log(error)
        })
    const promisePushDocument = firestore.collection(userInfo.placeType).doc(userInfo.placeID).collection("permanentGuests").doc(guestID).set(guestObjectWithInfo)
        .catch((error) => {
            console.log(error)
        })
    // const ref = '/' + userInfo.placeType + '/' + userInfo.stateID + '/' + userInfo.placeID
    return Promise.all([promiseFirestore, promisePushDocument, promiseLogEvent]).then(() => {
        return ({
            code: 200,
            message: "Successfully added Vendor to permanent passes",
            guestID: guestID,
            guestname: guestName,
            leftTime: 0,
            addToList: true,
            guestType: "guest",
            timeLimit: "perm"
        })
    }).catch((error) => {
        console.log(error)
        console.log("Error AddVendorToPerfunction")
        Promise.reject(error)
    })
}
/// End Permenent Functions
/// Start Temporary Functions
export async function addVendorToTemp(userInfo, guestName, startTime, leftTime, isToday, job, plate) {
    await Analytics.logEvent("Add_Guest", {
        name: "Add_Guest",
        guestType: "Vendor",
        placeID: userInfo.placeID,
        timeLimit: "Temporary",
        app: "Admin-Dashboard"
    })
    const returnPromise = new Promise((resolve, reject) => {
        db.ref('/' + userInfo.placeType + '/' + userInfo.stateID + '/' + userInfo.placeID + '/databaseActiveGuests/' + userInfo.houseID + '/' + userInfo.residentID)
            .push({
                'guestname': guestName,
                'startdate': Math.ceil(startTime),
                'enddate': Math.ceil(leftTime),
                'type': "vendor",
                'plate': plate,
                'job': job
            }).then((key) => {
                console.log("Pushed Successfully!")
                if (isToday) {
                    const ref = '/' + userInfo.placeType + '/' + userInfo.stateID + '/' + userInfo.placeID;
                    addToNewPassesFunction(ref, key.key, guestName, leftTime, "vendor", userInfo.houseID, userInfo.residentID, job, userInfo.residentPhone, userInfo.residentName, String(plate))
                        .then(() => {
                            resolve({
                                code: 200,
                                message: "Successfully added Vendor to permanent passes",
                                guestID: key.key,
                                guestname: guestName,
                                guestType: "vendor",
                                job: job,
                                leftTime: Math.ceil(leftTime)*1000,
                                addToList: true,
                                timeLimit: "temp"
                            })
                        }).catch((error) => {
                            console.log("Error in function addToNewPassesFunction")
                            reject(error)
                        })
                } else {
                    resolve({
                        code: 200,
                        message: "Successfully added Vendor to permanent passes",
                        guestID: key.key,
                        guestname: guestName,
                        job: job,
                        leftTime: Math.ceil(leftTime)*1000,
                        guestType: "vendor",
                        addToList: false,
                        timeLimit: "temp"
                    })
                    return;
                }
            }).catch((error) => {
                console.log("Error Insert to database Active Guests: ")
                console.log("Error Message:")
                console.log(error.message)
                console.log("Error code: " + error.code)
                console.log("...")
                reject(error.message)
            })
    })
    return returnPromise;
}

export async function addGuestToTemp(userInfo, guestName, startTime, leftTime, isToday, plate) {
    await Analytics.logEvent("Add_Guest", {
        name: "Add_Guest",
        guestType: "Guest",
        placeID: userInfo.placeID,
        timeLimit: "Temporary",
        app: "Admin-Dashboard"
    })
    const returnPromise = new Promise((resolve, reject) => {
        db.ref('/' + userInfo.placeType + '/' + userInfo.stateID + '/' + userInfo.placeID + '/databaseActiveGuests/' + userInfo.houseID + '/' + userInfo.residentID)
            .push({
                'guestname': guestName,
                'startdate': Math.ceil(startTime),
                'enddate': Math.ceil(leftTime),
                'plate': plate,
                'type': "guest"
            }).then((key) => {
                console.log("Pushed Successfully!")
                if (isToday) {
                    const ref = '/' + userInfo.placeType + '/' + userInfo.stateID + '/' + userInfo.placeID
                    addToNewPassesFunction(ref, key.key, guestName, leftTime, "guest", userInfo.houseID, userInfo.residentID, "", userInfo.residentPhone, userInfo.residentName, String(plate))
                        .then(() => {
                            resolve({
                                code: 200,
                                message: "Successfully added Guest to permanent passes",
                                guestID: key.key,
                                guestname: guestName,
                                leftTime: Math.ceil(leftTime)*1000,
                                addToList: true,
                                guestType: "guest",
                                timeLimit: "temp"
                            })
                        }).catch((error) => {
                            console.log("Error in function addToNewPassesFunction")
                            reject(error)
                        })
                } else {
                    resolve({
                        code: 200,
                        message: "Successfully added Vendor to permanent passes",
                        guestID: key.key,
                        guestname: guestName,
                        addToList: false,
                        leftTime: Math.ceil(leftTime)*1000,
                        guestType: "guest",
                        timeLimit: "temp"
                    })
                    return;
                }
            }).catch((error) => {
                console.log("Error Insert to database Active Guests: ")
                console.log("Error Message:")
                console.log(error.message)
                console.log("Error code: " + error.code)
                console.log("...")

                reject(error.message)
            })
    })
    return returnPromise;
}
/// End Temporary Functions
// Global Functions

async function addToNewPassesFunction(refLocation, guestID, guestname, lefttimePass, type, houseID, residentID, job, residentPhone, residentName, plate) {
    /// Handle Job of vendor by getting second name
    var object = await prepareTheObject(refLocation, guestID, guestname, lefttimePass, type, job, houseID, residentID, residentPhone, residentName, plate)
    const promiseReturn = await db.ref(refLocation + '/databaseNewPasses/').update(object)
        .catch((error) => {
            console.log("Error setting New Pass")
            console.log("Error Message:")
            console.log(error.message)
            console.log("Error code: " + error.code)
            console.log("...")

            Promise.reject({
                errorMessage: error.message,
                errorTitle: error.code,
            })
        })
    return promiseReturn;
}

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
//
function prepareTheObject(refLocation, guestID, guestname, timeLimit, type, job, houseID, residentID, phone, name, plate) {
    if (type === "vendor") {
        if (job !== "") {
            return {
                [guestID]: {
                    guestid: guestID,
                    guestname: guestname,
                    houseid: houseID,
                    residentid: residentID,
                    residentPhone: phone,
                    residentName: name,
                    type: type,
                    plate: plate,
                    lefttime: timeLimit,
                    job: job
                }
            }
        } else {
            return {
                [guestID]: {
                    guestid: guestID,
                    plate: plate,
                    guestname: guestname,
                    houseid: houseID,
                    residentid: residentID,
                    residentPhone: phone,
                    residentName: name,
                    type: type,
                    lefttime: timeLimit,
                }
            }
        }
    } else {
        return {
            [guestID]: {
                guestid: guestID,
                guestname: guestname,
                houseid: houseID,
                plate: plate,
                residentPhone: phone,
                residentName: name,
                residentid: residentID,
                lefttime: timeLimit,
                type: type
            }
        }
    }
}

function validNames(str) {
    if (str.length === 0) return false;
    if (str == null || str === undefined) return false;
    return str.match("^[A-Za-z() &]+$");
}
function validDates(first, second) {
    if (first == null || second == null) return false;
    if (String(first).length !== 10 || String(second).length !== 10) return false;
    if (Number(first) > Number(second)) return false;
    return true;
}
function checkIfToday(start) {
    var now = moment.tz('America/Los_Angeles').unix();
    if (now > start) return true;
    return false;
}

