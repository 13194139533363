import { Divider, List, ListItem, ListItemAvatar, ListItemText, Paper } from "@material-ui/core";
// Icons
import {
    AddCircle as FirstIcon,
    DeleteSweep as SecondIcon, 
    SendOutlined as ThirdIcon,
    Mail as ForthIcon
} from '@material-ui/icons';
// Styles
import React, { useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import useStyles from "./style";
// components
import AddNewHouse from "./Components/AddNewHouse";
import SendEmail from "./Components/SendEmail";
import BlastNotification from "./Components/BlastNotification";
import DeleteHouse from "./Components/DeleteHouse";
import { useUserDispatch } from "../../context/UserContext";





export default function Actions(props) {
    var [firstVisible, toggleFirstVisible] = useState(false);
    var [secondVisible, toggleSecondVisible] = useState(false);
    var [thirdVisible, toggleThirdVisible] = useState(false);
    var [forthVisible, toggleForthVisible] = useState(false);

    const [houseOwners] = useState(() => getHouseOwners(props.residentListMapProp))
    const userDispatch = useUserDispatch()
    // Feedback Function
    var classes = useStyles();
    return (
        <>
            <PageTitle title={"Actions"} />
            <div className={classes.root}>
                <Paper>
                    <List component="nav" aria-label="main mailbox folders">
                        <ListItem button onClick={() => toggleFirstVisible(!firstVisible)}>
                            <ListItemAvatar>
                                <FirstIcon className={classes.actionIcons}/>
                            </ListItemAvatar>
                            <ListItemText primary="Add a new house" />
                        </ListItem>
                        {firstVisible ?
                            <AddNewHouse dispatch={userDispatch} communityIdProp={props.communityIdProp} /> : <Divider variant="inset" component="li" />}
                        <ListItem button onClick={() => toggleSecondVisible(!secondVisible)}>
                            <ListItemAvatar>
                                <SecondIcon className={classes.actionIcons}/>
                            </ListItemAvatar>
                            <ListItemText primary="Delete house" />
                        </ListItem>
                        {secondVisible ?
                            <DeleteHouse dispatch={userDispatch} houseOwners={houseOwners} communityIdProp={props.communityIdProp}/> : <Divider variant="inset" component="li" />}
                        <ListItem button onClick={() => toggleThirdVisible(!thirdVisible)}>
                            <ListItemAvatar>
                                <ThirdIcon className={classes.actionIcons}/>
                            </ListItemAvatar>
                            <ListItemText primary="Send us feedback" />
                        </ListItem>
                        {thirdVisible ?
                            <SendEmail communityIdProp={props.communityIdProp} /> : <Divider variant="inset" component="li" />}
                        <ListItem button onClick={() => toggleForthVisible(!forthVisible)}>
                            <ListItemAvatar>
                                <ForthIcon className={classes.actionIcons}/>
                            </ListItemAvatar>
                            <ListItemText primary="Send resident blast" />
                        </ListItem>
                        {forthVisible ?
                            <BlastNotification communityIdProp={props.communityIdProp} /> : <Divider variant="inset" component="li" />}
                    </List>
                </Paper>
            </div>
        </>
    );
}

function getHouseOwners(map) {
    const array = []
    for (const residentID of Object.keys(map)) {
      if (residentID === map[residentID]['houseid']) {
        array.push(map[residentID])
      }
    }
    return array;
  }