import { Grid } from "@material-ui/core";
// import { useTheme } from "@material-ui/styles";
import React, { useState } from "react";
import PageTitle from "../../../components/PageTitle";
// components
// styles
// import useStyles from "./styles";

export default function Home(props) {
  // const classes = useStyles();
  // const theme = useTheme();
  const [titleDisplay] = useState('Hello ' + props.LoggedObject.displayedName)

  return (
    <>
      <PageTitle title={titleDisplay} />
      <Grid container spacing={1}>
        <Grid>

        </Grid>
      </Grid>
    </>
  );
}