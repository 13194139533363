import { Drawer, IconButton, List } from "@material-ui/core";
import {
    ArrowBack as ArrowBackIcon,
    FormatListBulleted as ListIcon,
    GroupAdd as AddGuest, Home as HomeIcon,
    InfoRounded as Info,
    SupervisedUserCircle
} from "@material-ui/icons";
// import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import { useTheme } from "@material-ui/styles";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
// context
import {
    toggleSidebar, useLayoutDispatch, useLayoutState
} from "../../context/LayoutContext";
// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
// styles
import useStyles from "./styles";




const structure = [
    { id: 0, label: "Home", link: "/app/dashboard", icon: <HomeIcon /> },
    { id: 1, label: "Guest List", link: "/app/resident/residents", icon: <SupervisedUserCircle /> },
    { id: 2, label: "Add Guest", link: "/app/resident/addguset", icon: <AddGuest /> },
    { id: 3, label: "Information", link: "/app/resident/information", icon: <Info /> },
    { id: 4, label: "History", link: "/app/resident/history", icon: <SupervisedUserCircle /> },
    { id: 5, label: "Actions", link: "/app/resident/actions", icon: <ListIcon /> }, // build page
    { id: 6, type: "divider" },
];

function ResidentSideBar({ location }) {
    var classes = useStyles();
    var theme = useTheme();

    // global
    var { isSidebarOpened } = useLayoutState();
    var layoutDispatch = useLayoutDispatch();

    // local
    var [isPermanent, setPermanent] = useState(true);

    useEffect(function () {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return function cleanup() {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });

    return (
        <Drawer
            variant={isPermanent ? "permanent" : "temporary"}
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: isSidebarOpened,
                [classes.drawerClose]: !isSidebarOpened,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: isSidebarOpened,
                    [classes.drawerClose]: !isSidebarOpened,
                }),
            }}
            open={isSidebarOpened}
        >
            <div className={classes.toolbar} />
            <div className={classes.mobileBackButton}>
                <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
                    <ArrowBackIcon
                        classes={{
                            root: classNames(classes.headerIcon, classes.headerIconCollapse),
                        }}
                    />
                </IconButton>
            </div>
            <List className={classes.sidebarList}>
                {structure.map(link => (
                    <SidebarLink
                        key={link.id}
                        location={location}
                        isSidebarOpened={isSidebarOpened}
                        {...link}
                    />
                ))}
            </List>
        </Drawer>
    );

    // ##################################################################
    function handleWindowWidthChange() {
        var windowWidth = window.innerWidth;
        var breakpointWidth = theme.breakpoints.values.md;
        var isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }
}

export default withRouter(ResidentSideBar);
