import { CardActionArea } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import classNames from 'classnames';
import React from 'react';
import { SectionSplitProps } from './components/SectionProps';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const TermsOfServices = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  alignTop,
  invertDesktop,
  invertMobile,
  ...props
}) => {
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    "illustration-section-01"
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );
  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );
  return (
    <section
      // {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className={splitClasses}>
            <div className="hero-content">
              <div className="container-s">
                <Card style={{ width: '100%', alignItems: 'center' }}>
                  <CardActionArea>
                    <CardContent>
                      <h3 className="mt-0 mb-16 reveal-from-bottom">
                        <span className="text-color-primary">
                          Terms Of Services
                </span>
                      </h3>
                      <ol>
                        <li>
                          <p style={{ fontSize: 13, fontWeight: 600, textAlign: 'left', color: 'black' }}>
                            By downloading or using the app, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the app. You’re not allowed to copy, or modify the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the app, and you also shouldn’t try to translate the app into other languages, or make derivative versions. The app itself, and all the trade marks, copyright, database rights and other intellectual property rights related to it, still belong to Liminal.
                            </p>
                        </li>
                        <li>
                          <p style={{ fontSize: 13, fontWeight: 600, textAlign: 'left', color: 'black' }}>
                            The content of the pages of this website is for your general information and use only. It is subject to change without notice. This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, the following personal information may be stored by us for use by third parties. This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
                            </p>
                        </li>
                        <li>
                          <p style={{ fontSize: 13, fontWeight: 600, textAlign: 'left', color: 'black' }}>
                            All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website. Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence. From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
                            </p>
                        </li>
                        <li>
                          <p style={{ fontSize: 13, fontWeight: 600, textAlign: 'left', color: 'black' }}>
                            Your use of this website and any dispute arising out of such use of the website is subject to the laws of England, Northern Ireland, Scotland and Wales.
                            </p>
                        </li>
                        <li>
                          <p style={{ fontSize: 13, fontWeight: 600, textAlign: 'left', color: 'black' }}>
                            The Liminal app stores and processes personal data that you have provided to us, in order to provide my Service. It’s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the Liminal app won’t work properly or at all.
                            </p>
                        </li>
                        <li>
                          <p style={{ fontSize: 13, fontWeight: 600, textAlign: 'left', color: 'black' }}>
                            The app does use third party services that declare their own Terms and Conditions. Link to Terms and Conditions of third party service providers used by the app <a style={{ color: 'blue' }} href="https://expo.io/terms"> <u>Expo</u></a>.
                            </p>
                        </li>
                        <li>
                          <p style={{ fontSize: 13, fontWeight: 600, textAlign: 'left', color: 'black' }}>
                            At some point, we may wish to update the app. The app is currently available on Android & iOS – the requirements for both systems(and for any additional systems we decide to extend the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. Liminal does not promise that it will always update the app so that it is relevant to you and/or works with the Android & iOS version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.
                            </p>
                        </li>
                        <li>
                          <p style={{ fontSize: 13, fontWeight: 600, textAlign: 'left', color: 'black' }}>
                            The Liminal app stores and processes personal data that you have provided to us, in order to provide my Service. It’s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the Liminal app won’t work properly or at all.
                            </p>
                        </li>
                        <li>
                          <p style={{ fontSize: 13, fontWeight: 600, textAlign: 'left', color: 'black' }}>
                            Liminal owners assume no responsiblity and shall have no liability whatoever for any property damage, personal or bodily injury, death, loss or damage of any other nature whatsoever including any loss or damage relating to your vehicle or its contents that may arise due to fire, theft, collision, negligence or gross negligence of Liminal, its agents or the Parking Lot owners or any other cause.
                            </p>
                        </li>
                      </ol>
                      <p style={{ fontSize: 13, fontWeight: 600, textAlign: 'left', color: 'black' }}>
                        <u>The use of this app is subject to the following terms of use</u>
                      </p>
                    </CardContent>
                  </CardActionArea>
                </Card>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

TermsOfServices.propTypes = propTypes;
TermsOfServices.defaultProps = defaultProps;

export default TermsOfServices;