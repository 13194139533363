import React, { useState, useEffect } from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import CircularProgress from '@material-ui/core/CircularProgress';

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar/Sidebar";
import ResidentSideBar from "../Sidebar/ResidentSideBar";

// Admin Dashboard Pages
import Dashboard from "../../pages/dashboard";
import ResidentList from "../../pages/residentlist"
import HistoryList from "../../pages/history"
import Updates from "../../pages/updates"
import Actions from "../../pages/settings/Actions"
import GuestsList from "../../pages/checkinguests/GuestsList";
import TodayEntrances from "../../pages/entrancetoday";
import PreRenderResidentScreen from '../../pages/residentlist/residentDetails/ResidentScreen'

// Resident Dashboard Pages
import Home from '../../pages/ResidentDashboard/home'
import History from '../../pages/ResidentDashboard/history'

// context
import { useLayoutState } from "../../context/LayoutContext";
import { useUserState } from "../../context/UserContext";

// Functions
import { getListsForCommunity, getResidentInfo } from './functions.js';

function LayoutControlState(props) {
  const userState = useUserState()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (userState.LoggedObject !== null) setLoading(false)
  }, [userState])
  if (loading) {
    return (
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100%' }}>
        <CircularProgress />
      </div>
    )
  } else {
    if (userState.LoggedObject.userType === "Resident") {
      return (
        <PreRenderResidentLayout {...props} userState={userState} />
      )
    } else {
      return (
        <PreRenderAdminLayout {...props} userState={userState} />
      )
    }
  }
}

function PreRenderAdminLayout(props) {
  // global
  var classes = useStyles();
  var layoutState = useLayoutState();

  const [loggedObject, setLoggedObject] = useState({})
  const [listValues, setListValues] = useState()
  const [stateDisplayedName, setDisplayedName] = useState("")
  const [placeList, setPlaceList] = useState([])
  // const [loadingFinished, setLoadingFinished] = useState(false)

  useEffect(() => {
    getListsForCommunity(props.userState.LoggedObject).then((values) => {
      if (props.userState.LoggedObject !== null && values !== null) {
        setListValues(values)
        setLoggedObject(props.userState.LoggedObject)
        setPlaceList(props.userState.LoggedObject.placeList)
        setDisplayedName(props.userState.LoggedObject.displayedName)
      }
    }).catch((error) => {
      console.log(error)
    })
  }, [props])

  // ###########################
  return (
    <div className={classes.root}>
      <>
        <Header
          history={props.history}
          LoggedObject={loggedObject}
          CommunityList={placeList}
          displayedName={stateDisplayedName}
        />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          {
            listValues ?
              <AdminLayout
                promiseValues={listValues}
                history={props.history}
              />
              :
              <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100%' }}>
                <CircularProgress />
              </div>
          }
        </div>
      </>
    </div>
  );
}

function AdminLayout(props) {
  // global
  const [currentCommunity] = useState(props.promiseValues.userObject)
  const [guestListArray] = useState(props.promiseValues.guestListArray)
  const [residentListMap, updateResidentList] = useState(props.promiseValues.residentListMap)
  const [houseOwnersMap] = useState(props.promiseValues.houseOwnersMap)
  return (
    <Switch>
      <Route path="/app/dashboard" render={(props) =>
        <Dashboard {...props} communityIdProp={currentCommunity} residentListMapProp={residentListMap} />} />
      <Route exact path="/app/residents" render={(props) =>
        <ResidentList {...props} history={props.history} communityIdProp={currentCommunity} residentListMapProp={residentListMap} />} />
      <Route path="/app/displayresident/:residentid" render={(props) =>
        <PreRenderResidentScreen updateResidentList={updateResidentList} {...props} communityIdProp={currentCommunity} residentListMapProp={residentListMap} />} />
      <Route path="/app/guestlist" render={(props) =>
        <GuestsList {...props} communityIdProp={currentCommunity} dataAddresses={houseOwnersMap} residentListMapProp={residentListMap} guestlistprop={guestListArray} />} />
      <Route path="/app/todayentrances" render={(props) =>
        <TodayEntrances {...props} communityIdProp={currentCommunity} residentListMapProp={residentListMap} />} />
      <Route path="/app/history" render={(props) =>
        <HistoryList {...props} communityIdProp={currentCommunity} residentListMapProp={residentListMap} />} />
      <Route path="/app/actions" render={(props) =>
        <Actions {...props} communityIdProp={currentCommunity} residentListMapProp={residentListMap} />} />
      <Route path="/app/updates" render={(props) =>
        <Updates {...props} />} />
    </Switch>
  );
}

function PreRenderResidentLayout(props) {
  // global
  const classes = useStyles();
  const layoutState = useLayoutState();

  const [LoggedObject, setLoggedObject] = useState(null)
  // const [loadingFinished, setLoadingFinished] = useState(false)

  useEffect(() => {
    getResidentInfo(props.userState.LoggedObject).then((values) => {
      if (props.userState.LoggedObject !== null && values !== null) {
        setLoggedObject({
          LoggedObject: props.userState.LoggedObject,
          HouseInfo: values
        })
      }
    }).catch((error) => {
      console.log(error)
    })
  }, [props.userState])

  // ###########################
  return (
    <div className={classes.root}>
      <>
        <Header
          history={props.history}
          LoggedObject={LoggedObject}
          CommunityList={[]}
        />
        <ResidentSideBar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          {
            LoggedObject ?
              <ResidentDashboard
                {...props}
                LoggedObject={LoggedObject}
              />
              :
              <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100%' }}>
                <CircularProgress />
              </div>
          }
        </div>
      </>
    </div>
  );
}

function ResidentDashboard(props) {
  // global
  const [LoggedObject] = useState(props.LoggedObject.LoggedObject)
  const [houseInfo] = useState(props.LoggedObject.HouseInfo)
  return (
    <Switch>
      <Route path="/app/dashboard/" render={(props) =>
        <Home {...props} LoggedObject={LoggedObject} houseInfo={houseInfo} />} />
      <Route path="/app/resident/history" render={(props) =>
        <History {...props} LoggedObject={LoggedObject} houseInfo={houseInfo} />} />

    </Switch>
  );
}

export default withRouter(LayoutControlState);
