import React from "react";
import { Grid } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import { sendPushNotification } from "../Functions";

export class PageBottomButtons extends React.PureComponent {
    clickSendMessage = () => {
        // If Message OK
        this.props.setIsLoading(true)
        const promiseReturn = sendPushNotification(this.props.userPushEnabled.expoToken, this.props.communityInfo, "message")// Gal Message
        promiseReturn.then((res) => {
            if (res.data.code === 200) {
                this.props.setSuccessAlert(true)
                this.props.setIsLoading(false)
            } else {
                this.props.setErrorAlert(true)
                this.props.setIsLoading(false)
            }
            return;
        }).catch((error) => {
            console.log(error)
            this.props.setErrorAlert(true)
            this.props.setIsLoading(false)
        })
    }
    render() {
        return (
            <Grid item container xs={8} spacing={1} alignItems="center" justify="center">
                <Grid item xs={3}>
                    <Fab
                        variant="extended"
                        color="primary"
                        className={this.props.classes.bottomButtons}
                        onClick={this.props.changeGuestsVisiblity}
                    >
                        {this.props.visible ? "Hide Guests" : "Show Guests"}
                    </Fab>
                </Grid>
                {/* <Grid item xs={3}>
                    <Fab
                        variant="extended"
                        color="secondary"
                        className={this.props.classes.bottomButtons}
                    >
                        Delete
                    </Fab>
                </Grid> */}
                {/* <Grid item xs={4}>
                    <Fab
                        variant="extended"
                        color="primary"
                        onClick={() => this.clickSendMessage()}
                        disabled={this.props.userPushEnabled.status}
                        className={this.props.classes.bottomButtons}
                    >
                        Send Message
                    </Fab>
                </Grid> */}
            </Grid>
        );
    }
}