import React from 'react';
import DesktopLayout from './components/responsiveLayout/Desktop/DesktopLayout';
import PhoneLayout from './components/responsiveLayout/Phone/PhoneLayout';
import './Home.css';


//

const Home = () => {
  if(window.screen.width > 700) {
    return (
      <DesktopLayout />
    )
  } else {
    return (
      <PhoneLayout />
    )
  }

}
export default Home;
