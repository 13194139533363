import firebase from 'firebase';
import { firebaseConfig } from './firebaseConfig'
import 'firebase/analytics'

let Firebase = firebase.initializeApp(firebaseConfig);
firebase.analytics();
const db = firebase.database()
const auth = firebase.auth()
const functions = firebase.app().functions('us-west2')
const firestore = firebase.firestore()
const firestoreFieldvalue = firebase.firestore;
const Analytics = firebase.analytics()
export { Firebase, db, auth, functions, firestore, firestoreFieldvalue, Analytics };