import { makeStyles } from '@material-ui/core/styles';

function normalize(size) {
    return size * 0.9
}

const normalFont = "MainFont"
//const boldFont = "MainFontBold"
const firstTitleFont = "Georgia";
const secondTitleFont = "serif";
const lightFont = "MainFontLight"
const mainTitleSize = normalize(55);
const titlesSize = normalize(40);
const secondaryTitleSize = normalize(30);
const textSize = normalize(32);
const footerSize = normalize(15);
const dashboardLoginSize = normalize(20);
// const normalFont = "MainFont"
// const boldFont = "MainFontBold"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundSize: 'cover',
        backgroundColor: 'white'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    logoContainer: {
        flexGrow: 1,
        marginRight: theme.spacing(2),
    },
    footerText: {
        fontSize: footerSize,
        color: "#87CEEB",
        marginTop: "2px"
    },
    footerTextLink: {
        fontSize: footerSize,
    },
    subtitleContainer: {
        marginBottom: 10,
        marginTop: 10,
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        alignItems: 'center',
    },
    subtitleSlide: {
        margin: 20,
    },
    contextMediaTextWhite: {
        color: 'white',
        fontFamily: normalFont,
        fontSize: textSize,
        marginRight: 10,
        marginTop: 10,
        textAlign: 'left'
    },
    contextMediaTextBlue: {
        color: 'black',
        fontFamily: normalFont,
        fontSize: textSize,
        marginLeft: 10,
        textAlign: 'left'
    },
    title: {
        flexGrow: 1,
        color: 'white',
        fontFamily: lightFont,
        fontSize: dashboardLoginSize,
        textTransform: "none"
    },
    whiteTitle: {
        // flexGrow: 1,
        color: 'white',
        fontFamily: firstTitleFont, secondTitleFont,
        fontSize: titlesSize,
        paddingTop: "15px"
    },
    blackTitle: {
        // flexGrow: 1,
        color: 'black',
        fontFamily: firstTitleFont, secondTitleFont,
        fontSize: titlesSize,
        paddingTop: "5px"
    },
    navBarLinks: {
        flexGrow: 1
    },
    secondaryTitle: {
        color: 'white',
        fontFamily: normalFont,
        fontSize: secondaryTitleSize
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        opacity: 0.8
    },
    bodyContainer: {
        width: '100%',
        height: '100%'
    },
    imageMedia: {
        height: 300,
        padding: 20,
        width: 200
    },
    mainTitle: {
        marginTop: 100,
        marginRight: 40,
        marginLeft: 40,
        fontFamily: firstTitleFont, secondTitleFont,
        fontSize: mainTitleSize,
        color: 'white'
    },
    ToolBar: {
        backgroundColor: 'black',
        paddingBottom: 5,
        flexGrow: 1
    },
    navBarButton: {
        color: 'white',
    },
    aboutUsParagraph: {
        color: 'black',
        fontFamily: normalFont, secondTitleFont,
        //fontWeight:'600',
        margin: 20,
        fontSize: textSize
    },
    navBar: {
        backgroundColor: 'black'
    },
    dashboardLoginButton: {
        "&:hover": {
            backgroundColor: "#FCB308",
            opacity: "0.8"
          },
    },
}));

export default useStyles;