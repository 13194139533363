import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

export class PlatesTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            addingPlate: false
        };
    }
    handleAddClick = () => {
        this.changeState()
    }
    onSave = () => {
        const newPlate = [{
            model: document.getElementById("plateModel").value,
            plate: document.getElementById("plateNumber").value,
            color: document.getElementById("plateColor").value,
            residentTag: document.getElementById("plateResidentTag").value,
            rfid: document.getElementById("plateRFID").value,
        }]
        this.props.addPlate(newPlate)
        this.changeState()
    }

    changeState = () => {
        this.setState((prevState) => {
            return { addingPlate: !prevState.addingPlate }
        })
    }

    render() {
        return (
            <Grid className={this.props.classes.containersBackground} item container xs={10} style={{display: "flex",
            justifyContent: "center",
            alignItems: "center"}}>
                <TableContainer component={Paper}>
                    <Table className={this.props.classes.tableWrap} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={this.props.classes.tableHeader}>Model</TableCell>
                                <TableCell className={this.props.classes.tableHeader}>Number</TableCell>
                                <TableCell className={this.props.classes.tableHeader}>Color</TableCell>
                                <TableCell className={this.props.classes.tableHeader}>Tag</TableCell>
                                <TableCell className={this.props.classes.tableHeader}>RFID</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.plates.map((plate) =>
                                <TableRow key={plate.plate}>
                                    <TableCell className={this.props.classes.table}>
                                        {plate.model ? plate.model : null}
                                    </TableCell>
                                    <TableCell className={this.props.classes.table}>
                                        {plate.plate ? plate.plate : null}
                                    </TableCell>
                                    <TableCell className={this.props.classes.table}>
                                        {plate.color ? plate.color : null}
                                    </TableCell>
                                    <TableCell className={this.props.classes.table}>
                                        {plate.residentTag ? plate.residentTag : null}
                                    </TableCell>
                                    <TableCell className={this.props.classes.table}>
                                        {plate.rfid ? plate.rfid : null}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            className={this.props.classes.secondaryActionButton}
                                            startIcon={<DeleteIcon size="small" />}
                                            style={{ fontSize: "10px" }}
                                            onClick={() => this.props.deletePlate(plate)}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )}
                            {this.state.addingPlate ?
                                <TableRow>
                                    <TableCell className={this.props.classes.table}>
                                        <TextField id="plateModel" label="Model" style={{ marginLeft: "10px" }} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField id="plateNumber" label="Number" />
                                    </TableCell>
                                    <TableCell>
                                        <TextField id="plateColor" label="Color" />
                                    </TableCell>
                                    <TableCell>
                                        <TextField id="plateResidentTag" label="Tag" />
                                    </TableCell>
                                    <TableCell>
                                        <TextField id="plateRFID" label="RFID" />
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                : null}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.addingPlate ? 
                <div>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={this.props.classes.plateButtons}
                    startIcon={<SaveIcon />}
                    onClick={this.onSave}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={this.props.classes.plateButtons}
                    startIcon={<ClearIcon />}
                    onClick={this.changeState}
                >
                    Cancel
                </Button>
                </div>
                 :
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={this.props.classes.plateButtons}
                            startIcon={<AddIcon />}
                            onClick={this.handleAddClick}
                        >
                            Add
                        </Button>
                    }
            </Grid>
        );
    }
}