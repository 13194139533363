import { Backdrop, Button, CircularProgress, Fab, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
// Dialog 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// Success Snack
import Snackbar from '@material-ui/core/Snackbar';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
// Icons
import BuildIcon from '@material-ui/icons/Build';
import DeleteIcon from '@material-ui/icons/Delete';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import MuiAlert from '@material-ui/lab/Alert';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Base64 from 'base-64';
import _ from 'lodash';
import moment from 'moment-timezone';
import MUIDataTable from "mui-datatables";
import React, { useRef, useState } from "react";
// Print
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useReactToPrint } from 'react-to-print';
// components
import PageTitle from "../../components/PageTitle/PageTitle";
// Firebase
import { db, firestore, firestoreFieldvalue } from '../../context/Firebase';
// House Info
import { InfoOldHouseList } from '../residentlist/residentDetails/InfoOldHouseList';
// Add Guest
import AddGuestDialog from './components/AddGuestDialog';
import { PrintablePass } from "./components/PrintablePass";
import { createGuestObject, getHouseInfo, _verifycarenter, _verifyentrance } from "./functions";
import useStyles from './styles';







export default function GuestTable(props) {
  var classes = useStyles();
  // var [guestList, setGuestList] = useState(props.guestlistprop)
  var [displayedGuests, setDisplayedGuests] = useState([])

  const [emptyListLabel, setEmptyListLabel] = useState("Choose address")
  const [loadingState, setLoadingState] = useState(false)
  const [residentListArray] = useState(() => getHouseOwners(props.residentListMapProp))
  const [currentHouseOwner, setCurrentHouseOwner] = useState(null)

  // Plate Verify Entrance Dialog States
  const [currentGuestObject, setCurrentGuestObject] = useState(null)
  const [dialogIsOpenInsertPlateState, setDialogIsOpenInsertPlateState] = useState(false)
  const [dialogPlateInputState, setDialogPlateInputState] = useState("")
  // Plate Verify Entrance Dialog States

  // Add Guest Dialog
  const [dialogIsOpenAddGuestState, setDialogIsOpenAddGuestState] = useState(false)
  // Add Guest Dialog

  // Snack States
  const [showSuccessSnack, setShowSuccessSnack] = useState(false)
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  // Snack States

  // Print Option
  const [printCheckBox, setPrintCheckBox] = useState(false)
  const printRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  // Print Option

  // More Data arrays
  const [notesArray, setNotesArray] = useState([])
  const [additionalPhones, setAdditionalPhones] = useState([])
  const [blockedGuests, setBlockedGuests] = useState([])
  // More Data arrays

  var [residentList] = useState(props.residentListMapProp)

  const filterOptions = createFilterOptions({
    stringify: ({ fullname, address }) => `${fullname} ${address}`
  });

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          fontSize: 12,
          fontWeight: '500'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: 11,
        }
      },
    }
  })

  const guestListColumns = [
    {
      name: "guestID",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      name: "GuestType",
      label: ".",
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          if (displayedGuests[dataIndex]['type'] === "Vendor") {
            return (
              <BuildIcon style={{ height: 15, width: 15 }} color="primary" />
            );
          } else {
            return null
          }

        }
      }
    },
    {
      name: "guestName",
      label: "Guest Name",
      options: {
        display: true,
        filter: false,
        sort: false,
        rowStyle: {
          fontSize: 9,
          overflow: 'visible'
        }
      }
    },
    {
      name: "residentName",
      label: "Resident Name",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "residentPhone",
      label: "Resident Phone",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <Typography noWrap={true} style={{ fontSize: 11 }} variant="body2">{makePhoneOk(displayedGuests[dataIndex]['residentPhone'])}</Typography>
          );
        }
      }
    },
    {
      name: "plate",
      label: "License Plate",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "Verify Entrance",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <Button variant="outlined" size="small" style={{ backgroundColor: '#00cc00', width: 50, height: 28 }} onClick={() => verifyEntranceHandler(createGuestObject(displayedGuests[dataIndex], currentHouseOwner))}>
              <DirectionsWalkIcon style={{ color: "white" }} />
            </Button>
          );
        }
      }
    },
    {
      name: "Verify Car Entrance",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <Button variant="outlined" size="small" style={{ width: 50, height: 28, backgroundColor: '#00b300' }} onClick={() => openPlateDialog(createGuestObject(displayedGuests[dataIndex], currentHouseOwner))}>
              <LocalTaxiIcon style={{ color: "white" }} />
            </Button>
          );
        }
      }
    },
    {
      name: "Delete Guest",
      label: ".",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <Tooltip title="Delete Guest" aria-label="add">
              <Fab size="small" style={{ backgroundColor: '#D11A2A' }} onClick={() => deleteGuestClicked(createGuestObject(displayedGuests[dataIndex], currentHouseOwner))}>
                <DeleteIcon style={{ color: "white", height: 20, width: 20 }} />
              </Fab>
            </Tooltip>
          );
        }
      }
    },
  ]

  var [placeValues] = useState({
    placeID: props.communityIdProp.placeID,
    placeType: props.communityIdProp.placeType,
    stateID: props.communityIdProp.stateID,
    timezone: props.communityIdProp.timezone
  })



  function deleteGuestClicked(deletedObject) {
    var promise = deleteOperation(deletedObject)
    promise.then(() => {
      var afterDeleteArray = _.filter(displayedGuests, current => current.guestID !== deletedObject.guestid);
      setDisplayedGuests(afterDeleteArray)
      setShowSuccessSnack(true)
      return;
    }).catch((error) => {
      console.log(error)
    })
  }

  async function deleteOperation(item) {
    if (item.lefttime === 0) {
      var deleteRef = item.residentid + '.' + item.guestid;
      const promiseDeleteHouseGuest = firestore.collection(placeValues.placeType).doc(placeValues.placeID).collection("permanentGuestsByHouseID").doc(item.houseid).update({
        [deleteRef]: firestoreFieldvalue.FieldValue.delete()
      })
        .catch((error) => {
          console.log(error)
          alert("Something went wrong")
          return error;
        })
      const promiseDeleteGuestDoc = firestore.collection(placeValues.placeType).doc(placeValues.placeID).collection("permanentGuests").doc(item.guestid).delete().catch((error) => {
        console.log(error)
        alert("Something went wrong")
        return error;
      })
      return Promise.all([promiseDeleteGuestDoc, promiseDeleteHouseGuest])
    } else {
      return db.ref("/" + placeValues.placeType + '/' + placeValues.stateID + '/' + placeValues.placeID + '/databaseActiveGuests/' + item.houseid + '/' + item.residentid + '/' + item.guestid).remove()
        .catch((error) => {
          console.log(error)
          alert("Something went wrong")
          return;
        })
    }
  }
  function handleAddressChosen(reason, houseOwnerObjectArray) {
    if (reason !== "clear") {
      setCurrentHouseOwner(houseOwnerObjectArray)
      setLoadingState(true)
      const promiseGetHouse = getHouseInfo(placeValues, houseOwnerObjectArray, residentList)
      promiseGetHouse.then((response) => {
        if (response.guestList.length === 0) {
          setEmptyListLabel("There are no guests for this house")
          setDisplayedGuests([])
          setBlockedGuests(response.moreInfo.blockedGuests)
          setNotesArray(response.moreInfo.notes)
          setAdditionalPhones(response.moreInfo.oldPhones)
        } else {
          setDisplayedGuests(response.guestList)
          setBlockedGuests(response.moreInfo.blockedGuests)
          setNotesArray(response.moreInfo.notes)
          setAdditionalPhones(response.moreInfo.oldPhones)
        }
        setLoadingState(false)
        return;
      }).catch((error) => {
        console.log(error)
        alert(error.message)
        setLoadingState(false)
      })
    } else {
      setCurrentHouseOwner(null)
      setCurrentGuestObject(null)
      setDialogPlateInputState("")
      setDisplayedGuests([])
      return;
    }
  }

  function openPlateDialog(guestObject) {
    setCurrentGuestObject(guestObject)
    setDialogIsOpenInsertPlateState(true)
  }
  function handleSubmitPlateHandler() {
    setDialogIsOpenInsertPlateState(false)
    setLoadingState(true)
    const newObject = currentGuestObject;
    newObject.plate = dialogPlateInputState
    setCurrentGuestObject(newObject)
    const promiseValidateEntrance = _verifycarenter(currentGuestObject, dialogPlateInputState, placeValues)
    promiseValidateEntrance.then((e) => {
      if(printCheckBox) {
        handlePrint()
      }
      setLoadingState(false)
      setShowSuccessSnack(true)
      setDialogPlateInputState("")
      return;
    }).catch((error) => {
      console.log(error)
      setDialogPlateInputState("")
      setLoadingState(false)
      return;
    })
    return;

  }

  function handleQR(item) {
    var endDate = "temp";
    if (item.lefttime === 0) endDate = "perm"
    const data = {
      "guestid": item.guestid,
      "placeid": placeValues.placeID,
      "placeType": placeValues.placeType,
      "residentid": item.residentid,
      "timeLimit": endDate,
      "code": '14211a3a7e1b',
    }
    return 'https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=' + Base64.encode(JSON.stringify(data))
  }

  function preparePrintObject(item) {
    if (item === null) {
      return {
        residentName: "",
        guestName: "",
        placeID: "",
        currentTime: "",
        expiredDate: "",
        address: "",
        plate: "",
        url: ""
      }
    } else {
      const currentTime = moment.tz('America/Los_Angeles').format('MM/DD/YY, h:mm a')
      let endTime = "Permanent";
      if (item.lefttime !== undefined && item.lefttime !== null & item.lefttime !== 0) endTime = moment(item.lefttime).tz('America/Los_Angeles').format('MM/DD/YY')
      return {
        residentName: item.residentName,
        guestName: item.guestname,
        placeID: placeValues.placeID,
        currentTime: currentTime,
        expiredDate: endTime,
        address: item.residentAddress,
        plate: item.plate,
        url: handleQR(item)
      }
    }

  }

  function verifyEntranceHandler(guestObject) {
    setCurrentGuestObject(guestObject)
    setLoadingState(true)
    if (guestObject.plate === "") {
      const promiseValidateEntrance = _verifyentrance(guestObject, placeValues)
      promiseValidateEntrance.then((e) => {
        if(printCheckBox) {
          handlePrint()
        }
        setLoadingState(false)
        setShowSuccessSnack(true)
        setDialogPlateInputState("")
        return;
      }).catch((error) => {
        setDialogPlateInputState("")
        console.log(error)
        setLoadingState(false)
        return;
      })
      return;
    } else {
      const promiseValidateEntrance = _verifycarenter(guestObject, guestObject.plate, placeValues)
      promiseValidateEntrance.then((e) => {
        if(printCheckBox) {
          handlePrint()
        }
        setLoadingState(false)
        setShowSuccessSnack(true)
        setDialogPlateInputState("")
        return;
      }).catch((error) => {
        setDialogPlateInputState("")
        console.log(error)
        setLoadingState(false)
        return;
      })
      return;
    }
  }

  return (
    <>
      <Grid container direction="row">
        <Grid item container xs={8}>
          <PageTitle title={"Check-In"} />
        </Grid>
        <Grid item xs={4} container justify="center" alignItems='center'>
          <FormControlLabel
            control={
              <Checkbox
                checked={printCheckBox}
                onChange={() => setPrintCheckBox(!printCheckBox)}
                name="checkedB"
                color="primary"
              />
            }
            label="Print pass"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid container justify="center" alignItems="center" >
          <Grid spacing={3} container direction="row" justify="center" style={{ height: 120 }}>
            <Grid container xs={8} justify="flex-end" alignItems="center" item>
              {/* <Grid container item xs={8} alignItems="center" justify="center">
                <Autocomplete
                  onChange={(event, value, reason) => handleAddressChosen(reason, value)}
                  options={_.uniqBy(residentListArray, 'address')}
                  getOptionLabel={(houseOwnerList) => houseOwnerList.address}
                  style={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Search by address" variant="outlined" />}
                  getOptionSelected={(option) => {
                    console.log(option)
                  }}
                />
              </Grid> */}
              <Grid container item xs={8} alignItems="center" justify="center">
                <Autocomplete
                  filterSelectedOptions
                  onChange={(event, value, reason) => handleAddressChosen(reason, value)}
                  onInputChange={(event) => event.target}
                  filterOptions={filterOptions}
                  options={residentListArray}
                  limitTags={1}
                  getOptionLabel={(residentList) => residentList.address}
                  fullWidth
                  renderOption={({ fullname, address }) => {
                    return (
                      <div>
                        <div>
                          <label style={{ fontWeight: '500', fontSize: 18, color: 'black' }}>{fullname}</label>
                        </div>
                        <div>
                          <label style={{ fontWeight: '400', fontSize: 15, color: 'grey' }}>{address}</label>
                        </div>
                      </div>
                    );
                  }}
                  renderTags={(tagValue, getTagProps) => {
                    return (
                      <div>
                        <div>
                          <label style={{ fontWeight: '500', fontSize: 18, color: 'black' }}>{tagValue[0].fullname}</label>
                        </div>
                        <div>
                          <label style={{ fontWeight: '400', fontSize: 15, color: 'grey' }}>{tagValue[0].address}</label>
                        </div>
                      </div>
                    )
                  }}
                  renderInput={(params) => <TextField {...params} label="Search by resident name or address" variant="outlined" />}
                />
              </Grid>
            </Grid>
            <Grid xs={4} container alignItems="center" item justify="flex-start">
              {currentHouseOwner ?
                <Tooltip title="Add Guest" aria-label="add" >
                  <Fab size="medium" onClick={() => setDialogIsOpenAddGuestState(true)} color="primary" aria-label="add">
                    <AddIcon />
                  </Fab>
                </Tooltip>
                : null}
            </Grid>
          </Grid>
          {(currentHouseOwner) ? <Grid item container xs={12} spacing={1} className={classes.textFieldRow}>
            <Grid style={{ marginBottom: 10 }} container item direction='row' xs={6} spacing={1}>
              <InfoOldHouseList
                // handleDeleteInfoClick={handleDeleteInfoClick} showDialogfunction={handleOpenInputDialog} 
                classes={classes} editable={false} title={"Blocked Guests"} type="blockedGuests" secondary={false} array={blockedGuests} gridSize={6} />
              <InfoOldHouseList
                // handleDeleteInfoClick={handleDeleteInfoClick} showDialogfunction={handleOpenInputDialog} 
                classes={classes} editable={false} type="additionalPhones" title={"Additional phones"} secondary={true} array={additionalPhones} gridSize={6} />
            </Grid>
            <Grid item xs={6} className={classes.gridContainerBox}>
              <InfoOldHouseList
                // handleDeleteInfoClick={handleDeleteInfoClick} showDialogfunction={handleOpenInputDialog} 
                classes={classes} editable={false} type="notes" title={"Notes"} secondary={false} array={notesArray} gridSize={12} />
            </Grid>
          </Grid> : null}
          <Grid item xs={12}>
            <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                data={displayedGuests}
                columns={guestListColumns}
                options={{
                  textLabels: {
                    body: {
                      noMatch: emptyListLabel
                    }
                  },
                  filter: false,
                  viewColumns: false,
                  print: false,
                  rowsPerPage: 25,
                  download: false,
                  search: false,
                  selectableRows: "none",
                  pagination: true,
                  rowsPerPageOptions: [25]
                }}
              />
            </MuiThemeProvider>
          </Grid>
        </Grid>
        <Snackbar open={showSuccessSnack} autoHideDuration={1600} onClose={() => setShowSuccessSnack(false)}>
          <Alert onClose={() => setShowSuccessSnack(false)} severity="success">Guest checked in!</Alert>
        </Snackbar>
        <Dialog maxWidth={'sm'} open={dialogIsOpenInsertPlateState} onClose={() => setDialogIsOpenInsertPlateState(false)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Please insert license plate</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              label="Fill here"
              fullWidth
              onChange={(event) => setDialogPlateInputState(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogIsOpenInsertPlateState(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleSubmitPlateHandler()} color="primary">
              Verify
            </Button>
          </DialogActions>
          {/* <ToastComponent showToast={openAlert} onClose={closeAlertSuccess} onOpen={showAlertSuccess} /> */}
        </Dialog>
        <AddGuestDialog currentGuestList={displayedGuests} toastSuccess={setShowSuccessSnack} placeValues={placeValues} houseInfo={currentHouseOwner} refreshGuestList={setDisplayedGuests} closeDialogFunction={setDialogIsOpenAddGuestState} dialogState={dialogIsOpenAddGuestState} />
        <Backdrop className={classes.backdrop} open={loadingState}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
      {currentGuestObject ?
        <div style={{ display: "none" }}>
          <PrintablePass ref={printRef} guestObject={preparePrintObject(currentGuestObject)} />
        </div>
        :
        <div/>
      }

    </>
  );
}

function makePhoneOk(phone) {
  if (phone === undefined || phone === null || String(phone).length < 10) return phone;
  return "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + "-" + phone.substring(6, 10)
}

function getHouseOwners(map) {
  const array = []
  for (const residentID of Object.keys(map)) {
    // if (residentID === map[residentID]['houseid']) {
    array.push(map[residentID])
    // }
  }
  return array;
}