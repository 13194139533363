import React, { useEffect } from "react";
import { auth } from './Firebase.js';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true, LoggedObject: action.ObjectSucess };
    case "SWITCH_PLACE":
      return { ...state, isAuthenticated: true, LoggedObject: action.ObjectSucess };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, LoggedObject: null };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false, LoggedObject: null };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("isAuthenticated"),
    LoggedObject: null
  });
  useEffect(() => {
    if (state.isAuthenticated) {
      var LoginInfo = JSON.parse(localStorage.getItem("LoginInfo"))
      if (LoginInfo !== null && LoginInfo !== undefined) {
        auth.signInWithEmailAndPassword(LoginInfo.email, LoginInfo.pass).then((user) => {
          user.user.getIdTokenResult(true).then((claims) => {
            var SuccessfullyLoginObject;
            if (claims.claims.Admin) {
              SuccessfullyLoginObject = {
                UID: user.user.uid,
                Email: user.user.email,
                stateID: claims.claims.stateid,
                placeID: claims.claims.placeid,
                placeType: claims.claims.type,
                placeList: [],
                userType: "Admin",
                displayedName: "",
                timezone: claims.claims.timezone
              }
              dispatch({ type: 'LOGIN_SUCCESS', ObjectSucess: SuccessfullyLoginObject })
              return;
            } else if (claims.claims.superAdmin) {
              SuccessfullyLoginObject = {
                UID: user.user.uid,
                Email: user.user.email,
                stateID: claims.claims.placeList[0]['state'],
                placeID: claims.claims.placeList[0]['value'],
                placeType: claims.claims.type,
                placeList: claims.claims.placeList,
                timezone: claims.claims.timezone,
                userType: "superAdmin",
                displayedName: user.user.displayName
              }
              dispatch({ type: 'LOGIN_SUCCESS', ObjectSucess: SuccessfullyLoginObject })
              return;
            } else {
              let bossValue = false;
              if(claims.claims.boss !== undefined && claims.claims.boss !== null ) bossValue = true;
              SuccessfullyLoginObject = {
                UID: user.user.uid,
                Email: user.user.email,
                stateID: claims.claims.stateid,
                placeID: claims.claims.placeid,
                placeType: claims.claims.type,
                userType: "Resident",
                isHouseOwner: bossValue,
                houseID: claims.claims.houseid,
                placeList: [],
                displayedName: user.user.displayName,
                timezone: claims.claims.timezone
              }
              dispatch({ type: 'LOGIN_SUCCESS', ObjectSucess: SuccessfullyLoginObject })
              return;
            }
          }).catch((error) => {
            console.log(error.message)
            localStorage.removeItem("isAuthenticated");
            localStorage.removeItem("LoginInfo");
            dispatch({ type: "LOGIN_FAILURE" });
          })
        }).catch((error) => {
          console.log(error.message);
          localStorage.removeItem("isAuthenticated");
          localStorage.removeItem("LoginInfo");
          dispatch({ type: "LOGIN_FAILURE" });
        })
      } else {
        dispatch({ type: "LOGIN_FAILURE" });
      }
    }
  }, [state.isAuthenticated])
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}


export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, switchPlace };

// ###########################################################

function switchPlace(dispatch, newLoggeedObject, isLoadingFunction, setErrorFunction) {
  if (newLoggeedObject !== null) {
    dispatch({ type: 'SWITCH_PLACE', ObjectSucess: newLoggeedObject })
  }
}

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);
  if (!!login && !!password) {
    auth.signInWithEmailAndPassword(login, password).then((user) => {
      user.user.getIdTokenResult(true).then((claims) => {
        var SuccessfullyLoginObject;
        var LoginInfo;
        if (claims.claims.Admin === true) {
          SuccessfullyLoginObject = {
            UID: user.user.uid,
            Email: user.user.email,
            stateID: claims.claims.stateid,
            placeID: claims.claims.placeid,
            placeType: claims.claims.type,
            placeList: [],
            userType: "Admin",
            displayedName: "",
            timezone: claims.claims.timezone
          }
          LoginInfo = {
            email: login,
            pass: password,
          }
          localStorage.setItem("isAuthenticated", true)
          localStorage.setItem("LoginInfo", JSON.stringify(LoginInfo))
          dispatch({ type: 'LOGIN_SUCCESS', ObjectSucess: SuccessfullyLoginObject })
          history.push('/app/dashboard');
          return;
        } else if (claims.claims.superAdmin) {
          SuccessfullyLoginObject = {
            UID: user.user.uid,
            Email: user.user.email,
            stateID: claims.claims.placeList[0]['state'],
            placeID: claims.claims.placeList[0]['value'],
            placeType: claims.claims.type,
            placeList: claims.claims.placeList,
            userType: "superAdmin",
            displayedName: user.user.displayName,
            timezone: claims.claims.timezone
          }
          LoginInfo = {
            email: login,
            pass: password,
          }
          localStorage.setItem("isAuthenticated", true)
          localStorage.setItem("LoginInfo", JSON.stringify(LoginInfo))
          dispatch({ type: 'LOGIN_SUCCESS', ObjectSucess: SuccessfullyLoginObject })
          history.push('/app/dashboard/');
          return;
        } else {
          // let bossValue = false;
          // if(claims.claims.boss !== undefined && claims.claims.boss !== null ) bossValue = true;
          // SuccessfullyLoginObject = {
          //   UID: user.user.uid,
          //   Email: user.user.email,
          //   stateID: claims.claims.stateid,
          //   placeID: claims.claims.placeid,
          //   placeType: claims.claims.type,
          //   userType: "Resident",
          //   placeList: [],
          //   isHouseOwner: bossValue,
          //   houseID: claims.claims.houseid,
          //   displayedName: user.user.displayName,
          //   timezone: claims.claims.timezone
          // }
          // LoginInfo = {
          //   email: login,
          //   pass: password,
          // }
          // localStorage.setItem("isAuthenticated", true)
          // localStorage.setItem("LoginInfo", JSON.stringify(LoginInfo))
          // dispatch({ type: 'LOGIN_SUCCESS', ObjectSucess: SuccessfullyLoginObject })
          // history.push('/app/dashboard/');
          alert("Only admins can access the portal currently")
          dispatch({type: 'LOGIN_FAILURE'})
          return;
        }
      }).catch((error) => {
        console.log(error)
        setIsLoading(false);
        setError(true);
      })
    }).catch((error) => {
      setError(true);
      setIsLoading(false);
      console.log(error);
    })
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("LoginInfo");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  // history.push("/login");
}