import React from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { enterTodayColumns } from '../../config/columns'
import { enterTodayOptions } from '../../config/options'

export default class LastCheckIn extends React.Component {
  getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          fontSize: 11
        }
      },
      MUIDataTableHeadCell: {
        root: {
          fontSize: 12,
        }
      },
    }
  })
  render() {
    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <MUIDataTable data={this.props.data} columns={enterTodayColumns} options={enterTodayOptions} />
      </MuiThemeProvider>
    );

  }
}


