import { Grid } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React from 'react';
import useStyles from "./styles";

export default function SplitButton(props) {
    var classes = useStyles();
    const [realValue, setRealValue] = React.useState(props.realValue);

    const handleFormat = (event, newFormats) => {
        if (newFormats === null) return;
        props.setParentValue(newFormats)
        setRealValue(newFormats);
    };

    return (
        <Grid item container xs={"auto"} justify="center" alignItems="center">
            <ToggleButtonGroup variant="outlined" color="primary" value={realValue} style={{ width: 220, height: 50 }} onChange={handleFormat} exclusive aria-label="text formatting">
                <ToggleButton className={classes.outlinedChosen} value={props.firstValue} style={{ textTransform: 'none', color: 'black', fontWeight: '500', width: '50%', borderTopLeftRadius: 30, borderBottomLeftRadius: 30, height: 50 }}>
                    {props.firstOption}
                </ToggleButton>
                <ToggleButton className={classes.outlinedChosen} value={props.secondValue} style={{ textTransform: 'none', color: 'black', fontWeight: '500', width: '50%', borderTopRightRadius: 30, borderBottomRightRadius: 30, height: 50 }}>
                    {props.secondOption}
                </ToggleButton>
            </ToggleButtonGroup>
        </Grid>
    );
}