import React from "react";
// import FlatList from 'flatlist-react';
import { Fab } from '@material-ui/core';
import { switchPlace } from '../../context/UserContext'
import _ from 'lodash'

export default class CommunityList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.CommunityList,
            loggedObject: this.props.LoggedObject,
            selectedIndex: 0
        }
        this.clickChangeCommunity = this.clickChangeCommunity.bind(this);

    }
    componentDidMount() {
        if (this.props.LoggedObject !== undefined) {
            var currentIndex = _.find(this.state.data, ['value', this.props.LoggedObject.placeID])
            this.setState({ selectedIndex: currentIndex.index })
        }
    }
    clickChangeCommunity(item, index) {
        if (item.value === this.state.loggedObject.placeID) {
            return;
        }
        this.setState({ selectedIndex: index })
        var SuccessfullyLoginObject = {
            UID: this.state.loggedObject.UID,
            Email: this.state.loggedObject.Email,
            stateID: item.state,
            placeID: item.value,
            placeType: this.state.loggedObject.placeType,
            placeList: this.state.loggedObject.placeList,
            timezone: this.state.loggedObject.timezone,
            userType: this.state.userType,
            displayedName: this.state.loggedObject.displayedName
        }
        switchPlace(this.props.dispatch, SuccessfullyLoginObject)
        if (this.props.history.location.pathname !== "/app/dashboard/") {
            this.props.history.push("/app/dashboard/")
        }
    }

    _keyExtractor = item => item.value

    renderPerson = (item) => {
        if (this.state.selectedIndex === item.index) {
            return (
                <li key={item.value} style={{ listStyle: 'none', justifyContent: 'space-between' }}>
                    <Fab onClick={() => this.clickChangeCommunity(item)} aria-label="edit" style={{ width: 120, borderRadius: 8, backgroundColor: '#FCB308', height: 20, marginLeft: 6, marginRight: 6, alignItems: "center", borderColor: 'black', borderWidth: 2 }}>
                        <label style={{ fontSize: 14, fontFamily: 'MainFont', color: 'white', textTransform: 'none', fontWeight: 'bold' }}>{item.label}</label>
                    </Fab>
                </li>
            );
        } else {
            return (
                <li key={item.value} style={{ listStyle: 'none', justifyContent: 'space-between' }}>
                    <Fab style={{ width: 120, borderRadius: 8, backgroundColor: '#b3f0ff', height: 20, marginLeft: 6, marginRight: 6, alignItems: "center", borderColor: 'black', borderWidth: 2 }} onClick={() => this.clickChangeCommunity(item, item.index)} aria-label="edit">
                        <label style={{ fontSize: 14, fontFamily: 'MainFont', color: 'darkgrey', textTransform: 'none', fontWeight: 'bold' }}>{item.label}</label>
                    </Fab>
                </li>
            );
        }
    }

    render() {
        return (
            <ul style={{ height: 36, direction: 'ltr', display: 'flex' }}>
                {this.state.data.map(item => this.renderPerson(item))}
            </ul>
        )
    }
}