import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  avatarStyle: {
    width: 25,
    height: 25,
    backgroundColor: "#0092b3",
  },
  listItemTextPrimary: {
    fontSize: 13,
    color: 'black',
    fontWeight: '700'
  },
  listItemTextSecondry: {
    fontSize: 11,
    color: 'grey'
  },
}));
