import React from "react";
import { Grid } from "@material-ui/core";
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography';

export class TitleDivider extends React.PureComponent {
    render() {
        return (
            (this.props.sideToTitleAdd) ?
                <Grid container alignItems="center" justify="center" item>
                    <Grid item><Typography variant={this.props.titleSize} align="center">{this.props.title}</Typography></Grid>
                    <Grid item xs={12}><Divider></Divider></Grid>
                </Grid>
                :
                <Grid container alignItems="center" justify="center" item>
                    <Grid item><Typography variant="h3" align="center">{this.props.title}</Typography></Grid>
                    <Grid item xs={12}><Divider></Divider></Grid>
                </Grid>
        )
    }
}