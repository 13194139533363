import { makeStyles } from '@material-ui/core/styles';

function normalize(size) {
    return size*0.57
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundSize: 'cover',
        backgroundColor: 'white'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    logoContainer: {
        flexGrow: 1,
        marginRight: theme.spacing(2),
    },
    footerText: {
        fontSize: normalize(18),
        color: 'white',
    },
    footerTextLink: {
        fontSize: normalize(18)
    },
    subtitleContainer: {
        margin: 0,
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        alignItems: 'center',
        marginTop: 20,
    },
    subtitleSlide: {
        margin: 10,
    },
    contextMediaTextWhite: {
        color: 'white',
        fontFamily: 'MainFont',
        fontSize: normalize(28),
        marginLeft: 10,
        textAlign: 'left'
    },
    contextMediaTextBlue: {
        color: 'black',
        fontFamily: 'MainFont',
        fontSize: normalize(28),
        marginLeft: 10,
        textAlign: 'left'
    },
    title: {
        flexGrow: 1,
        color: 'white',
        fontFamily: 'MainFont',
        textTransform: "none"
    },
    whiteTitle: {
        // flexGrow: 1,
        color: 'white',
        fontFamily: 'MainFont',
        textTransform: "none"
    },
    blackTitle: {
        // flexGrow: 1,
        color: 'black',
        fontFamily: 'MainFont',
        textTransform: "none"
    },
    navBarLinks: {
        flexGrow: 1
    },
    secondaryTitle: {
        color: 'white',
        fontFamily: 'MainFont',
        fontSize: normalize(26)
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        opacity: 0.8
    },
    bodyContainer: {
        width: '100%',
        height: '100%'
    },
    imageMedia: {
        height: 260,
        padding: 20,
        width: 180
    },
    mainTitle: {
        marginTop: 200,
        marginRight: 10,
        marginLeft: 10,
        fontFamily: 'MainFont',
        fontSize: normalize(78),
        color: 'white'
    },
    ToolBar: {
        backgroundColor: 'black',
        paddingBottom: 5,
        flexGrow: 1
    },
    navBarButton: {
        color: 'white',
    },
    aboutUsParagraph: {
        color: 'black',
        fontFamily: 'MainFont',
        margin: 16,
        fontSize: normalize(21)
    },
    navBar: {
        backgroundColor: 'black'
    },
}));

export default useStyles;