import _ from 'lodash';
import { db, firestore, auth, firestoreFieldvalue, functions } from '../../context/Firebase';
import { Alert } from '@material-ui/lab';
import moment from 'moment-timezone'

export async function getResidentObject(residentListMap, residentID, communityInfo) {
    const userObject = await getUserInfo(residentListMap[residentID], residentID, communityInfo)
    const houseObject = await getHouseInfo(residentListMap, residentID, communityInfo)
    return {
        userInfo: userObject,
        houseInfo: houseObject
    }
}

async function getUserInfo(residentObject, residentID, communityInfo) {
    const responseExpoToken = await db.ref('/' + communityInfo.placeType + '/' + communityInfo.stateID + '/' + communityInfo.placeID + '/' + residentObject.houseid + '/' + residentID + '/expotoken').once("value")
        .then((e) => e.val())
        .catch((error) => {
            console.log("Error fetching Expo Token")
            console.log(error)
            return 0;
        })
    return {
        residentName: residentObject.fullname,
        email: residentObject.email,
        phone: residentObject.phone,
        pushNotification: {
            status: checkIfPushEnabled(responseExpoToken),
            expoToken: responseExpoToken
        }
    }
}

function checkIfPushEnabled(token) {
    if (token === 0 || token === undefined || token === null) return true;
    else {
        return false;
    }
}

async function getHouseInfo(residentsMap, residentID, communityInfo) {
    var mapHouseResident = _.pickBy(residentsMap, { houseid: residentsMap[residentID].houseid });
    var houseResidents = await getHouseResidents(mapHouseResident, residentID, residentsMap[residentID].houseid)
    var houseDocument = await getHouseDocument(residentsMap[residentID].houseid, communityInfo)
    return {
        address: residentsMap[residentID].address,
        ownerName: houseDocument.ownerName,
        houseID: residentsMap[residentID].houseid,
        plates: houseDocument.plates,
        residents: houseResidents.residentsArray,
        notes: houseDocument.notes,
        blockedguests: houseDocument.blockedguests,
        oldResidentArray: houseDocument.oldResidents,
        oldPhonesArray: houseDocument.oldPhones
    }
}

function getHouseDocument(houseID, communityInfo) {
    return firestore.collection(communityInfo.placeType).doc(communityInfo.placeID).collection("houses").doc(houseID).get()
        .then(async (e) => {
            if (e.data()) {
                const platesArray = arrangePlateArray(e.data().plates)
                const oldResidents = arrangeOldDataInArray(e.data().otherResidents)
                const oldPhones = arrangeOldDataInArray(e.data().additionalPhones)
                const notes = arrangeOldDataInArray(e.data().notes)
                const blockedguests = arrangeBlockedGuestsInArray(e.data().blockedguests)
                return {
                    plates: platesArray,
                    oldResidents: oldResidents,
                    oldPhones: oldPhones,
                    notes: notes,
                    blockedguests: blockedguests,
                    ownerName: e.data()['fullname']
                }
            } else {
                return {
                    plates: [],
                    oldResidents: [],
                    oldPhones: [],
                    notes: [],
                    blockedguests: [],
                    ownerName: ""
                }
            }
        }).catch((error) => {
            console.log(error)
            return {
                plates: [],
                oldResidents: [],
                oldPhones: [],
                notes: [],
                blockedguests: []
            }
        })
}

function arrangePlateArray(array) {
    var returnArray = [];
    for (const i in array) {
        returnArray.push({
            model: fixUndefinedFields(array[i]['model']),
            color: fixUndefinedFields(array[i]['color']),
            plate: fixUndefinedFields(array[i]['plate']),
            carID: fixUndefinedFields(array[i]['carID']),
            residentTag: fixUndefinedFields(array[i]['residentTag']),
            rfid: fixUndefinedFields(array[i]['rfid'])
        })
    }
    return returnArray;
}

function arrangeOldDataInArray(map) {
    if (map !== undefined && map !== null) {
        if (Object.keys(map).length > 0) {
            var returnArray = [];
            for (const key in map) {
                if (map[key]['secondary'] === null || map[key]['secondary'] === undefined) {
                    returnArray.push({
                        primary: map[key]['primary'],
                        id: key
                    })
                } else {
                    returnArray.push({
                        primary: map[key]['primary'],
                        secondary: map[key]['secondary'],
                        id: key
                    })
                }
            }
            return returnArray;
        } else {
            return []
        }
    } else {
        return []
    }
}

function arrangeBlockedGuestsInArray(map) {
    if (map !== undefined && map !== null) {
        if (Object.keys(map).length > 0) {
            var returnArray = [];
            for (const key in map) {
                returnArray.push({
                    guestname: map[key]['guestname'],
                    createdAt: map[key]['createdAt'],
                    id: key
                })
            }
            return returnArray;
        } else {
            return []
        }
    } else {
        return []
    }
}

function getHouseResidents(houseResidentMap, displayedResidentID, houseID) {
    var returnArray = [];
    for (const residentID in houseResidentMap) {
        if (residentID !== displayedResidentID) {
            var bossValue = false;
            if (residentID === houseID) bossValue = true;
            if (houseResidentMap[residentID].fullname !== undefined && houseResidentMap[residentID] !== undefined) {
                returnArray.push({
                    residentID: residentID,
                    residentName: houseResidentMap[residentID].fullname,
                    phone: houseResidentMap[residentID].phone,
                    boss: bossValue
                })
            }
        }
    }
    return {
        residentsArray: returnArray
    };
}

export async function pushNewPlateToDatabase(objectToPush, communityInfo, houseID) {
    const residentTagField = fixUndefinedFields(objectToPush[0].residentTag)
    const rfidField = fixUndefinedFields(objectToPush[0].rfid)
    if (validateInputs(objectToPush[0].color, objectToPush[0].plate, objectToPush[0].model, residentTagField, rfidField)) return Promise.reject({ code: 101, message: "Please fill all values" })
    const placeRef = '/' + communityInfo.placeType + '/' + communityInfo.stateID + '/' + communityInfo.placeID;
    return db.ref(placeRef + '/databasePlates/' + houseID).push({ color: objectToPush[0].color, plate: objectToPush[0].plate, model: objectToPush[0].model, residentTag: residentTagField, rfid: rfidField })
        .then((test) => {
            return firestore.collection(communityInfo.placeType).doc(communityInfo.placeID).collection("houses").doc(houseID)
                .update({
                    plates: firestoreFieldvalue.FieldValue.arrayUnion({
                        carID: test.key,
                        model: objectToPush[0].model,
                        color: objectToPush[0].color,
                        plate: objectToPush[0].plate,
                        residentTag: residentTagField,
                        rfid: rfidField
                    })
                }).then(() => {
                    return ({ code: 200, message: "Success!" });
                }).catch((error) => {
                    console.log(error)
                    console.log("Firestore error")
                    return ({ code: 102, message: error.message });
                })
        })
        .catch((error) => {
            console.log("Database Error insert")
            console.log(error)
            return ({ code: 103, message: error.message });
        })
}

export async function deleteCarFromDatabase(deleteObject, communityInfo, houseID) {
    if (validateInputs(deleteObject.color, deleteObject.plate, deleteObject.model, deleteObject.residentTag, deleteObject.rfid)) return Promise.reject({ code: 101, message: "Please fill all values" })
    const placeRef = '/' + communityInfo.placeType + '/' + communityInfo.stateID + '/' + communityInfo.placeID;
    return firestore.collection(communityInfo.placeType).doc(communityInfo.placeID).collection("houses").doc(houseID)
        .update({ plates: firestoreFieldvalue.FieldValue.arrayRemove(deleteObject) })
        .then(() => {
            return db.ref(placeRef + '/databasePlates/' + houseID + '/' + deleteObject.carID).remove()
                .then(() => {
                    return ({ code: 200, message: "Success!" });
                }).catch((error) => {
                    console.log("Error firestore delete")
                    console.log(error)
                    return ({ code: 102, message: error.message });
                })
        }).catch((error) => {
            console.log("Error realtime delete")
            console.log(error)
            return ({ code: 103, message: error.message });
        })
}

function fixUndefinedFields(field) {
    return (field === null || field === undefined) ? "" : field;
}

function validateInputs(a, b, c) {
    if ((a === null || a === undefined) || (b === null || b === undefined) || (c === null || c === undefined)) return true;
    return false;
}

export function createEditInputRequestObject(newValue, lastValue, placeID, houseID, residentID) {
    return auth.currentUser.getIdToken(true).then((idToken) => {
        return {
            idToken: idToken,
            newValue: newValue,
            houseID: houseID,
            lastValue: lastValue,
            placeID: placeID,
            residentID: residentID
        }
    }).catch((error) => {
        console.log(error)
        Alert("Some error occurd")
        return {
            idToken: "",
            lastValue: lastValue,
            newValue: newValue,
            houseID: houseID,
            placeID: placeID,
            residentID: residentID
        }
    })
}

export function httpRequestEditUserInfo(url, data) {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
        .then((response) => response.json())
        .then((res) => {
            console.log(res)
            return res;
        })
        .catch((error) => {
            return error;
        })
}

export function getRealtimeGuests(communityInfo, houseID, residentID) {
    return db.ref('/' + communityInfo.placeType + '/' + communityInfo.stateID + '/' + communityInfo.placeID + '/databaseActiveGuests/' + houseID + '/' + residentID).once("value")
        .then((e) => {
            var returnArray = [];
            if (e.val() !== undefined && e.val() !== null) {
                const databaseSnapshot = e.val()
                for (const guestID in databaseSnapshot) {
                    var job = ""
                    if (databaseSnapshot[guestID]['job'] !== undefined && databaseSnapshot[guestID]['job'] !== "") job = databaseSnapshot[guestID]['job'];
                    returnArray.push({
                        guestID: guestID,
                        guestName: databaseSnapshot[guestID]['guestname'],
                        type: capitalizeFirstLetter(databaseSnapshot[guestID]['type']),
                        timeLimit: "Temporary",
                        job: job,
                        lefttime: moment.tz(databaseSnapshot[guestID]['enddate'] * 1000, communityInfo.timezone).format("MM/DD")
                    })
                }
                return returnArray;
            } else {
                return returnArray;
            }
        }).catch((error) => {
            console.log("Error realtime database")
            console.log("Error code: " + error.code)
            console.log("Message : " + error.message)
            return [];
        })
}

export function createEmailSendingHouseIdObject(houseID, Email, name, placeID) {
    return auth.currentUser.getIdToken(true).then((idToken) => {
        return {
            idToken: idToken,
            emailToSend: Email,
            houseID: houseID,
            residentName: name,
            placeID: placeID
        }
    }).catch((error) => {
        console.log(error)
        alert("Some error occurd")
        return {
            idToken: "",
            emailToSend: Email,
            houseID: houseID,
            residentName: name,
            placeID: placeID
        }
    })
}

export function getFirestoreGuests(communityInfo, houseID, residentID) {
    return firestore.collection(communityInfo.placeType).doc(communityInfo.placeID).collection("permanentGuestsByHouseID").doc(houseID).get()
        .then((e) => {
            const snapshotDocument = e.data()[residentID];
            var returnArray = [];
            if (snapshotDocument !== null && snapshotDocument !== undefined) {
                for (const guestID in snapshotDocument) {
                    var job = ""
                    if (snapshotDocument[guestID]['job'] !== undefined && snapshotDocument[guestID]['job'] !== "") job = snapshotDocument[guestID]['job'];
                    returnArray.push({
                        guestID: guestID,
                        guestName: snapshotDocument[guestID]['guestname'],
                        type: capitalizeFirstLetter(snapshotDocument[guestID]['type']),
                        timeLimit: "Permanent",
                        job: job,
                        lefttime: ""
                    })
                }
                return returnArray;
            } else {
                return returnArray;
            }
        }).catch((error) => {
            console.log("Error Firestore database")
            console.log("Error code: " + error.code)
            console.log("Message : " + error.message)
            return [];
        })
}

export function getFirestoreBlockedGuests(communityInfo, houseID) {
    return firestore.collection(communityInfo.placeType).doc(communityInfo.placeID).collection("houses").doc(houseID).get()
        .then((e) => {
            const snapshotDocument = e.data().blockedguests;
            var returnArray = [];
            if (snapshotDocument !== null && snapshotDocument !== undefined) {
                for (const index in Object.values(snapshotDocument)) {
                    returnArray.push({
                        createdAt: moment.tz(Object.values(snapshotDocument)[index].createdAt.seconds * 1000, communityInfo.timezone).format("MM/DD/YY"),
                        guestname: Object.values(snapshotDocument)[index].guestname,
                    })
                }
                return returnArray;
            } else {
                return returnArray;
            }
        }).catch((error) => {
            console.log("Error Firestore database")
            console.log("Error code: " + error.code)
            console.log("Message : " + error.message)
            return [];
        })
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function sendPushNotification(expoToken, communityInfo, message) {
    return functions.httpsCallable('sendExpoNotificationWebsite')({ body: message, expoToken: expoToken, validateCode: "dlknmalk3dsfg", placeID: communityInfo.placeID })
        .then((e) => {
            return e;
        }).catch((error) => {
            console.log("Error inside")
            console.log(error)
            return error;
        })
}

export function addHouseData(type, data, placeType, placeID, houseID, currentArray) {
    const randomString = String(makeid(6))
    const addRef = type + "." + randomString
    const promise = firestore.collection(placeType).doc(placeID).collection("houses").doc(houseID).update({
        [addRef]: data
    }).catch((error) => {
        console.log(error)
        return error
    })
    return promise.then(() => {
        var object = [];
        if (type === "notes" || type === "oldDataResidents") {
            object.push({
                primary: data.primary,
                id: randomString
            })
        } else {
            object.push({
                primary: data.primary,
                secondary: data.secondary,
                id: randomString
            })
        }
        return object.concat(currentArray)
    }).catch((error) => {
        console.log(error)
        return currentArray
    })
}

export function addBlockedGuestFunction(guestName, placeType, placeID, houseID, currentBlockedGuests) {
    const randomString = String(makeid(6))
    const addRef = "blockedguests." + randomString
    const timestamp = firestoreFieldvalue.FieldValue.serverTimestamp()
    return firestore.collection(placeType).doc(placeID).collection("houses").doc(houseID).update({
        [addRef]: {
            createdAt: timestamp,
            guestname: guestName
        }
    }).then(() => {
        const currentArray = currentBlockedGuests;
        currentArray.push({
            guestname: guestName,
            createdAt: timestamp,
            id: randomString
        })
        return { code: 200, array: currentArray };
    }).catch((error) => {
        console.log(error)
        return { code: 300, message: error.message, array: currentBlockedGuests };
    })
}
export function deleteHouseData(type, deleteID, placeType, placeID, houseID, currentArray) {
    const deletedRef = type + "." + deleteID
    const promise = firestore.collection(placeType).doc(placeID).collection("houses").doc(houseID).update({
        [deletedRef]: firestoreFieldvalue.FieldValue.delete()
    }).catch((error) => {
        console.log(error)
        return error
    })
    return promise.then(() => {
        return _.remove(currentArray, item => item.id !== deleteID)
    }).catch((error) => {
        console.log(error)
        alert("Something went wrong")
        return currentArray;
    })
}

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
